import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { RegisterUsersBatch_v2 } from '../../../library/utils/API/apiRegisterUsersBatch';

import { Cancel } from '@mui/icons-material';
import { Modal, Grid, Button, Typography, IconButton, TextField, FormControl } from '@mui/material';

import getEstablishmentId from '../../../library/utils/API/Establishment/apiReadEstablishment';

import updateTrialActionsAction from 'js/core/actions/trialActions';
import RequisitionTrial from 'js/library/services/TrialRequisition';

import { toast, ToastContainer } from 'react-toastify';
import InputMask from 'react-input-mask';
import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';

import * as S from './styles';

import { maskCPF2, getUserInfo, randomizeNumbersString } from '../../../library/utils/helpers';
import { useStyles } from './useStyles';
import { getTrialStatus } from 'js/library/utils/API/Trial/apiGetTrial';
import { updateTrialStatus } from 'js/library/utils/API/Trial/apiUpdateTrial';

export function RegisterUserModal({
  isModalRegisterUserOpen,
  handleCloseModalRegisterUser,
  dataCellphone,
}) {
  const dispatch = useDispatch();

  const [inputNameValue, setInputNameValue] = useState('');
  const [inputEmailValue, setInputEmailValue] = useState('');
  const [inputCellPhoneValue, setInputCellPhoneValue] = useState('');

  const [inputBirthDateValue, setInputBirthDateValue] = useState('');
  const [inputCpfValue, setInputCpfValue] = useState('');
  const [establishmentName, setEstablishmentName] = useState('');
  const establishmentId = window.location.href.split('/').find((e) => e.startsWith('-'));
  const [cellPhoneIsNull, setCellPhoneIsNull] = useState(false);
  const [fullNameIsNull, setFullNameIsNull] = useState(false);
  const [modalStepIsOpen, setModalStepIsOpen] = useState(false);
  let state = useSelector((state) => state.authModel);

  const teste = window.location.href.split('/').find((e, i) => {
    console.log(e, i, e.startsWith('-'));
    return e.startsWith('-');
  });
  console.log('------------', teste, establishmentId);

  const [currentDDD, setCurrentDDD] = useState('+55');
  const [inputMask, setInputMask] = useState('(99) 99999-9999');
  const [cellphone, setCellphone] = useState('');

  const [currentCountry, setCurrentCountry] = useState('');
  const [fullMask, setFullMask] = useState('+55(99)99999-9999');

  const handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(',');
    setCurrentDDD(valuesArr[0]);
    setInputMask(valuesArr[1]);
    setFullMask(valuesArr[2]);
    setCurrentCountry(e.target.value);
  };

  useEffect(() => {
    dataCellphone
      ? setCellphone(currentDDD + dataCellphone.replace(/\D/g, ''))
      : setCellphone(currentDDD + inputCellPhoneValue.replace(/\D/g, ''));
  }, [inputCellPhoneValue]);

  useEffect(() => {
    const getNameEstablishment = async () => {
      return getEstablishmentId(establishmentId).then((result) => {
        setEstablishmentName(result.nome);
      });
    };
    getNameEstablishment();
  }, []);

  const classes = useStyles();

  const handleOnChangeName = (event) => {
    setInputNameValue(event.target.value);
  };

  const handleOnChangeEmail = (event) => {
    setInputEmailValue(event.target.value);
  };

  const handleOnChangeDate = (event) => {
    setInputBirthDateValue(event.target.value);
  };

  const handleOnChangeCPF = (event) => {
    setInputCpfValue(maskCPF2(event.target.value));
  };

  const handleSubmit = () => {
    const fullMaskFormated = '+' + fullMask.replace(/\D/g, '');

    if (cellphone.length !== fullMaskFormated.length) {
      toast.warn('Número de celular inválido.');
      setCellPhoneIsNull(true);
      return;
    }

    if (inputNameValue === '') {
      toast.warn('Nome do cliente obrigatório.');
      setFullNameIsNull(true);
      return;
    }

    try {
      let objectWithData = {
        establishmentName: establishmentName,
        establishmentId: establishmentId,
        name: inputNameValue,
        cellphone: cellphone,
      };
      let userInfosValidates = [];

      const cpf = inputCpfValue.replace(/\D/g, '');

      if (inputEmailValue !== '') {
        objectWithData.email = inputEmailValue;
      }

      if (inputBirthDateValue !== '') {
        const birthDate = new Date(inputBirthDateValue).getTime() + 1000 * 60 * 60 * 3;
        objectWithData.birthDate = birthDate;
      }

      if (cpf !== '') {
        objectWithData.cpf = cpf;
      }

      userInfosValidates.push(objectWithData);

      RegisterUsersBatch_v2(userInfosValidates, establishmentName, establishmentId);

      const uid = getUserInfo().uId;
      getTrialStatus(uid).then((result) => {
        updateTrialStatus({
          establishmentStep: result.establishmentStep,
          storeStep: result.storeStep,
          relationshipStep: true,
          congrats: result.congrats,
        }).then((result) => {
          if (
            window.pathname !== null &&
            JSON.parse(JSON.stringify(window.sessionStorage)).thirdStep === 'incomplete'
          ) {
            window.sessionStorage.setItem('thirdStep', 'complete');
            setModalStepIsOpen(true);
          }
        });
      });

      setInputNameValue('');
      setInputEmailValue('');
      setInputBirthDateValue('');
      setInputCpfValue('');
      setInputCellPhoneValue('');

      setCellPhoneIsNull(false);
      setFullNameIsNull(false);
      setTimeout(() => {
        handleCloseModalRegisterUser();
      }, 2000);

      toast.success(
        'Cliente cadastrado com sucesso! Em breve você poderá consultá-los na aba "Ver meus clientes".'
      );
    } catch (error) {
      handleCloseModalRegisterUser();
      toast.error('Erro ao cadastrar cliente.');
    }

    RequisitionTrial().then((result) => {
      dispatch(updateTrialActionsAction(result));
    });
  };

  if (modalStepIsOpen === true) {
    return (
      <Redirect
        to={{
          pathname: `/estabelecimento/${state.establishmentList[0].id}/thirdStep`,
          state: state.establishmentList[0],
        }}
      />
    );
  } else {
    return (
      <>
        <ToastContainer autoClose={6000} />
        <Modal
          open={isModalRegisterUserOpen}
          onClose={handleCloseModalRegisterUser}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <FormControl className={classes.modalStyle}>
            <IconButton
              onClick={handleCloseModalRegisterUser}
              edge="end"
              style={{
                position: 'fixed',
                top: 0,
                right: 0,
                padding: '0',
                margin: '0.5em',
              }}
            >
              <Cancel />
            </IconButton>
            <Typography variant="h5" component="span" className={classes.titleModal}>
              Cadastro de clientes
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  error={fullNameIsNull ? true : false}
                  helperText={fullNameIsNull ? 'Campo obrigatório' : ''}
                  label="* Nome completo"
                  variant="outlined"
                  value={inputNameValue}
                  onChange={handleOnChangeName}
                  fullWidth
                  className={classes.modalInput1}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label="E-mail"
                  variant="outlined"
                  fullWidth
                  type="email"
                  value={inputEmailValue}
                  onChange={handleOnChangeEmail}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <S.BoxCellPhone cellPhoneIsNull={cellPhoneIsNull}>
                  <select
                    style={{
                      fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                      fontSize: '17px',
                      color: '#383838d6',
                      border: 'none',
                    }}
                    value={currentCountry}
                    onChange={handleCountryChange}
                  >
                    {phoneMaskArray.map((country, index) => (
                      <option
                        style={{
                          fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                        }}
                        key={country.id}
                        value={[country.ddd, country.mask, country.fullMask]}
                      >
                        {country.emoji} {country.initials} {country.ddd}
                      </option>
                    ))}
                  </select>

                  <InputMask
                    style={{
                      fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                      fontSize: '17px',
                      color: '#383838d6',
                      border: 'none',
                    }}
                    mask={inputMask}
                    value={dataCellphone || inputCellPhoneValue}
                    placeholder={'*' + randomizeNumbersString(inputMask)}
                    onChange={(e) => setInputCellPhoneValue(e.target.value)}
                    disabled={dataCellphone}
                  />
                </S.BoxCellPhone>
                {cellPhoneIsNull && (
                  <p
                    style={{
                      color: '#f44336',
                      marginlLeft: '14px;',
                      fontWeight: '400',
                      fontSize: '12px',
                    }}
                  >
                    Campo obrigatório
                  </p>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="outlined-basic"
                  label="Data de nascimento"
                  placeholder=""
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  value={inputBirthDateValue}
                  onChange={handleOnChangeDate}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  maxLength="14"
                  id="outlined-basic"
                  label="CPF"
                  variant="outlined"
                  value={inputCpfValue}
                  onChange={handleOnChangeCPF}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSubmit}
              className={classes.modalButtonSubmit}
            >
              Cadastrar
            </Button>
          </FormControl>
        </Modal>
      </>
    );
  }
}

