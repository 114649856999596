import { Route, withRouter, Switch } from 'react-router-dom';
import React from 'react';

import Welcome from 'js/components/Welcome/Welcome';
import LotteryList from 'js/components/Configurations/LotteryList';
import AdminConfiguration from 'js/components/Configurations/AdminConfiguration';
import AdminConfigurationExternal from 'js/components/Configurations/AdminConfigurationExternal';
import FinancialCredit from 'js/components/Configurations/FinancialCredit';
import SearchUser from 'js/components/Configurations/SearchUser';
import SetCards from 'js/components/Configurations/SetCards.js';
import SortCards from 'js/components/Configurations/SortCards.js';
import ListCards from 'js/components/Configurations/ListCards.js';
import OrganizationInformation from 'js/components/Configurations/OrganizationInformation.js';
import KeywordsList from 'js/components/Configurations/KeywordsList';
import KeywordsBatch from 'js/components/Configurations/KeywordsBatch';
import BatchActions from 'js/components/Configurations/BatchActions.js';
import BatchActionsEdit from 'js/components/Configurations/BatchActionsEdit.js';
import Stamping from 'js/components/Configurations/Stamping';
import GenerateLink from 'js/components/Configurations/GenerateLink.js';
import Users from 'js/components/Configurations/Users.js';
import EstablishmentScreen from 'js/components/Establishment/EstablishmentScreen';
import indexCredits from 'js/components/Establishment/indexCredits';
import AttendanceScreen from 'js/components/Establishment/AttendanceScreen';
import ClientScreen from 'js/components/Establishment/ClientScreen';
import ResultsScreen from 'js/components/Establishment/ResultsScreen';
import RelationshipScreen from 'js/components/Establishment/RelationshipScreen';
import ContactTriiboExternal from 'js/components/Store/ContactTriiboExternal';
import QrcodeList from 'js/components/Configurations/QrcodeList';
import QrcodeInformation from 'js/components/Configurations/QrcodeInformation';
import StorePromotionExternal from 'js/components/Store/Promotion/StorePromotionExternal';
import StoreGroupExternal from 'js/components/Store/Group/StoreGroupExternal';
import EstablishmentApprovalList from 'js/components/Configurations/EstablishmentApprovalList';
import EstablishmentApprovedList from 'js/components/Configurations/EstablishmentApprovedList';
import StoreApprovalList from 'js/components/Configurations/StoreApprovalList';
import StoreApprovedList from 'js/components/Configurations/StoreApprovedList';
import StoreListExternal from 'js/components/Store/StoreListExternal';
import StoreBusinessPartnerExternal from 'js/components/Store/BusinessPartner/StoreBusinessPartnerExternal';
import StorePromotionInformation from 'js/components/Store/Promotion/StorePromotionInformation';
import StoreGroupInformation from 'js/components/Store/Group/StoreGroupInformation';
import StoreMenu from 'js/components/Store/StoreMenu';
import StoreGenerationInformation from 'js/components/Store/Generation/StoreGenerationInformation';
import QRCodeGenerate from 'js/components/Store/QRCode/QRCodeGenerate';
import PromotionTemplate from 'js/components/Promotions/PromotionTemplate';
import PromotionForm from 'js/components/Promotions/PromotionForm';
import LotteryEdit from 'js/components/Configurations/LotteryEdit';
import Promote from 'js/components/Establishment/PromoteScreen';
import TemplatesList from 'js/components/Configurations/MarketingTemplates/TemplatesList';
import UserTemplatesInformation from 'js/components/Configurations/MarketingTemplates/UserTemplatesInformation';
import MarketingScreen from 'js/components/Establishment/MaterialMarketing';
import WidgetWhatsapp from 'js/components/WidgetWhatsapp/WidgetWhatsapp';
import NotFound from 'js/components/ErrorPages/NotFound';
import RegistrationOption from 'js/components/Establishment/RelationshipScreen/RegistrationOption';
import RegisterListUsers from 'js/components/Establishment/RelationshipScreen/RegisterListUsers';
import ListUsers from 'js/components/Establishment/RelationshipScreen/ListUsers';
import MyBusiness from 'js/components/MyBusiness';
import StepsConcludedPage from 'js/components/StepsConcluded/ModalFirstStep/StepsConcludedPage';
import SecondStepConcludedPage from 'js/components/StepsConcluded/ModalSecondStep/SecondStepConcludedPage';
import ThirdStepConcludedPage from 'js/components/StepsConcluded/ModalThirdStep/ThirdStepConcludedPage';
import FAQ from 'js/components/FAQ/FAQ';
import SendCommunication from 'js/components/Configurations/Communications/SendCommunication';
import EstablishmentCreateNew from 'js/components/Establishment/EstablishmentCreateNew';
import RegisterUsefullLinks from 'js/components/Establishment/RelationshipScreen/RegisterUsefullLinks';
import EstablishmentEdit from 'js/components/Establishment/EstablishmentEdit';
import BasicRegistration from 'js/components/Configurations/CreateClub/BasicRegistration';
import { ClubTable } from 'js/components/Clubs/ClubTable';
import RegisterCampaigns from 'js/components/Configurations/Campaigns/RegisterCampaign';
import ListCampaigns from 'js/components/Configurations/Campaigns/ListCampaigns';
import Campaigns from 'js/components/Configurations/Campaigns';
import RegisterCampaignMapfre from 'js/components/Configurations/CampaignMapfre/RegisterCampaignMapfre';
import ListCampaignsLp from 'js/components/Configurations/CampaignMapfre/ListCampaignsMapfre';
import CampaignMapfre from 'js/components/Configurations/CampaignMapfre';
import AdminFunctions from 'js/components/Configurations/AdminFunctions';
import EditClub from 'js/components/Configurations/CreateClub/EditClub';
import ListCommunications from 'js/components/Configurations/Communications/ListCommunications';
import RelationshipClub from 'js/components/Configurations/CreateClub/RelationshipClub/index';
import RegistrationOptionClub from 'js/components/Configurations/CreateClub/RelationshipClub/RegistrationOption';
import RegisterListUsersClub from 'js/components/Configurations/CreateClub/RelationshipClub/RegisterListUsers';
import ListUsersClub from 'js/components/Configurations/CreateClub/RelationshipClub/ListUsers';
import { CardManagement } from 'js/components/Configurations/CreateClub/CardManagement/CardManagement';
import ListEstablishment from 'js/components/Establishment/ListEstablishment';
import ClubMenu from 'js/components/Configurations/CreateClub/ClubMenu';
import ClubManageAdmin from 'js/components/Configurations/CreateClub/ClubManageAdmin';
import EstablishmentEditApproval from 'js/components/Establishment/EstablishmentEditApproval';
import EstablishmentCuration from 'js/components/Establishment/EstablishmentCuration/index';
import ListPosts from 'js/components/Configurations/Posts/ListPosts';
import SendPosts from 'js/components/Configurations/Posts/SendPosts';
import ConfigHomeMapfre from 'js/components/Configurations/ConfigHomeMapfre';
import CreateModels from 'js/components/Configurations/ConfigHomeMapfre/CreateModels';
import ListModules from 'js/components/Configurations/ConfigHomeMapfre/ListModules';
import EditModules from 'js/components/Configurations/ConfigHomeMapfre/EditModules';
import OrganizeModules from 'js/components/Configurations/ConfigHomeMapfre/OrganizeModules';
import FormSignUp from 'js/components/Configurations/CreateClub/FormSignUp';
import { AccessPermission } from 'js/components/Configurations/CreateClub/AccessPermissions/AccessPermissions';
import { EditWhitelist } from 'js/components/Configurations/CreateClub/AccessPermissions/EditWhitelist/EditWhitelist';
import AttachWhitelist from 'js/components/Configurations/CreateClub/AccessPermissions/AttachWhitelist/AttachWhitelist';
import MapfreMenu from 'js/components/Configurations/MapfreMenu';
import Livelo from 'js/components/Configurations/Livelo/Livelo';
import PostPoints from 'js/components/Configurations/Livelo/PostPoints';
import OrganizationsList from 'js/components/Configurations/Organizations/OrganizationsList';
import ForbiddenWords from 'js/components/Configurations/CreateClub/ForbiddenWords';
import NpsFormSignUp from 'js/components/Configurations/CreateClub/NpsFormSignUp';
import { LandingPageList } from 'js/components/Configurations/CreateClub/LandingPage/LandingPageList/index';
import LandingPageSignUp from 'js/components/Configurations/CreateClub/LandingPage/LandingPageSignUp/index';
import VoucherCampaign from 'js/components/Configurations/Campaigns/VoucherCampaign';
import ListLogin from 'js/components/Configurations/CustomLogin/ListLogin';
import LoginManager from 'js/components/Configurations/CustomLogin/LoginManager';
import { SegmentationList } from 'js/components/Clubs/Segmentation/SegmentationList';
import { MemberManagement } from 'js/components/Clubs/Segmentation/MemberManagement';
import { AddSegmentation } from 'js/components/Clubs/Segmentation/AddSegmentation';
import Rules from 'js/components/Clubs/Segmentation/Rules';
import ResourceManagement from 'js/components/Clubs/Segmentation/ResourceManagement';
import PartnerCode from 'js/components/Configurations/Campaigns/PartnerCode';

const AdminRouter = () => {
  return (
    <>
      <WidgetWhatsapp />
      <div style={{ height: '100%', width: '100%' }}>
        <Switch>
          {/*SEM VALIDAÇÃO*/}
          <Route exact path="/admin/estabelecimentos/" component={ListEstablishment} />
          <Route exact path="/admin/" component={Welcome} />

          <Route exact path="/admin/estabelecimento/curadoria" component={EstablishmentCuration} />

          <Route
            exact
            path="/admin/estabelecimentos/adicionar"
            component={EstablishmentCreateNew}
          />

          <Route exact path="/admin/estabelecimento/:id" component={EstablishmentScreen} />

          <Route exact path="/admin/estabelecimento/:id/ofertas" component={StoreListExternal} />
          <Route exact path="/admin/estabelecimento/:id/ofertas/tipos" component={StoreMenu} />
          <Route
            exact
            path="/admin/estabelecimento/:id/promocoes/qrcode/generate"
            component={QRCodeGenerate}
          />
          <Route
            exact
            path="/admin/estabelecimento/:id/ofertas/promocao/add"
            component={StorePromotionInformation}
          />
          <Route
            exact
            path="/admin/estabelecimento/:id/ofertas/promocao/edit/:idOferta"
            component={StorePromotionInformation}
          />
          <Route
            exact
            path="/admin/estabelecimento/:id/ofertas/promocaoAgrupada/add"
            component={StoreGroupInformation}
          />
          <Route
            exact
            path="/admin/estabelecimento/:id/ofertas/promocaoAgrupada/edit/:idOferta"
            component={StoreGroupInformation}
          />
          <Route
            exact
            path="/admin/estabelecimento/:id/ofertas/empresasParceiras/add"
            component={StoreBusinessPartnerExternal}
          />
          <Route
            exact
            path="/admin/estabelecimento/:id/ofertas/empresasParceiras/edit/:idOferta"
            component={StoreBusinessPartnerExternal}
          />
          <Route
            exact
            path="/admin/estabelecimento/:id/promocaoexterna/:promoId/editar"
            component={StoreBusinessPartnerExternal}
          />
          <Route
            exact
            path="/estabelecimento/:id/promocaoexterna/adicionar"
            component={StoreBusinessPartnerExternal}
          />
          <Route
            exact
            path="/estabelecimento/:id/promocaoexterna/:promoId/editar"
            component={StoreBusinessPartnerExternal}
          />
          <Route exact path="/admin/store/gerarVoucher" component={StoreGenerationInformation} />
          <Route
            exact
            path="/admin/estabelecimento/:id/administradores"
            component={AdminConfigurationExternal}
          />
          <Route exact path="/admin/configuracoes/" component={AdminFunctions} />
          <Route exact path="/admin/configuracoes/administradores" component={AdminConfiguration} />
          <Route
            exact
            path="/admin/configuracoes/listaNotificacoes"
            component={ListCommunications}
          />
          <Route exact path="/admin/configuracoes/listaPostagem" component={ListPosts} />
          <Route exact path="/admin/configuracoes/enviaPostagem" component={SendPosts} />
          <Route path="/admin/configuracoes/enviaPostagem/:id" component={SendPosts} />
          <Route exact path="/admin/configuracoes/creditarPontos" component={FinancialCredit} />
          <Route exact path="/admin/configuracoes/pesquisarUsuario" component={SearchUser} />

          <Route exact path="/admin/configuracoes/cadastrarCards" component={SetCards} />
          <Route exact path="/admin/configuracoes/cards" component={ListCards} />
          <Route exact path="/admin/configuracoes/cards/:id" component={SetCards} />
          <Route exact path="/admin/configuracoes/organizarCards" component={SortCards} />
          <Route exact path="/admin/configuracoes/sorteios" component={LotteryList} />
          <Route exact path="/admin/configuracoes/sorteio/:id" component={LotteryEdit} />
          <Route exact path="/admin/configuracoes/organizacoes" component={OrganizationsList} />
          <Route
            exact
            path="/admin/configuracoes/organizacoes/adicionar"
            component={OrganizationInformation}
          />
          <Route
            exact
            path="/admin/configuracoes/organizacoes/editar/:id"
            component={OrganizationInformation}
          />
          <Route exact path="/admin/configuracoes/Keywords" component={KeywordsList} />
          <Route
            exact
            path="/admin/configuracoes/Keywords/Gerenciamento"
            component={KeywordsBatch}
          />
          <Route exact path="/admin/configuracoes/gerarLink" component={GenerateLink} />
          <Route exact path="/admin/configuracoes/acoeslote" component={BatchActions} />
          <Route exact path="/admin/configuracoes/acoeslote/add" component={BatchActionsEdit} />
          <Route exact path="/admin/configuracoes/acoeslote/:id" component={BatchActionsEdit} />
          <Route exact path="/admin/configuracoes/carimbos" component={Stamping} />
          <Route exact path="/admin/configuracoes/qrcodes" component={QrcodeList} />
          <Route
            exact
            path="/admin/configuracoes/qrcodes/adicionar"
            component={QrcodeInformation}
          />
          <Route
            exact
            path="/admin/configuracoes/qrcodes/editar/:id"
            component={QrcodeInformation}
          />
          <Route exact path="/admin/configuracoes/usuarios" component={Users} />
          <Route exact path="/admin/configuracoes/templates" component={TemplatesList} />
          <Route
            exat
            path="/admin/configuracoes/templates/adicionar"
            component={UserTemplatesInformation}
          />
          <Route
            exact
            path="/admin/configuracoes/templates/editar/:id"
            component={UserTemplatesInformation}
          />
          <Route exact path="/admin/configuracoes/campanhaMapfre" component={CampaignMapfre} />
          <Route
            exact
            path="/admin/configuracoes/campanhaMapfre/adicionarCampanha"
            component={RegisterCampaignMapfre}
          />
          <Route
            exact
            path="/admin/configuracoes/campanhaMapfre/listarCampanhas"
            component={ListCampaignsLp}
          />
          <Route
            exact
            path="/admin/configuracoes/campanhas/atualizarCampanhaMapfre/campaignId=:id"
            component={RegisterCampaignMapfre}
          />
          <Route exact path="/admin/configuracoes/mapfre" component={MapfreMenu} />
          <Route exact path="/admin/configuracoes/loginPersonalizado" component={ListLogin} />
          <Route
            exact
            path="/admin/configuracoes/loginPersonalizado/adicionar"
            component={LoginManager}
          />
          <Route
            exact
            path="/admin/configuracoes/loginPersonalizado/editar/:id"
            component={LoginManager}
          />
          <Route
            exact
            path="/admin/configuracoes/campanhas/adicionarCampanha"
            component={RegisterCampaigns}
          />

          <Route
            exact
            path="/admin/configuracoes/campanhas/adicionarCampanha/:id"
            component={RegisterCampaigns}
          />
          <Route
            exact
            path="/admin/configuracoes/campanhas/atualizarCampanha/campaignId=:id"
            component={RegisterCampaigns}
          />
          <Route
            exact
            path="/admin/configuracoes/campanhas/subirVoucher/campaignId=:id"
            component={VoucherCampaign}
          />
          <Route
            exact
            path="/admin/configuracoes/campanhas/cadastroCodigo/campaignId=:id/ownerId=:ownerId"
            component={PartnerCode}
          />
          <Route
            exact
            path="/admin/configuracoes/campanhas/listarCampanhas"
            component={ListCampaigns}
          />
          <Route exact path="/admin/configuracoes/configHomeMapfre" component={ConfigHomeMapfre} />
          <Route
            exact
            path="/admin/configuracoes/configHomeMapfre/adicionarModulos"
            component={CreateModels}
          />
          <Route
            exact
            path="/admin/configuracoes/configHomeMapfre/listarModulos"
            component={ListModules}
          />
          <Route
            exact
            path="/admin/configuracoes/configHomeMapfre/editarModulos/:id"
            component={EditModules}
          />
          <Route
            exact
            path="/admin/configuracoes/configHomeMapfre/organizarModulos"
            component={OrganizeModules}
          />
          <Route exact path="/admin/configuracoes/pontos-livelo/" component={Livelo} />
          <Route exact path="/admin/configuracoes/pontos-livelo/post" component={PostPoints} />
          <Route
            exact
            path="/admin/estabelecimento/:id/administradores"
            component={AdminConfigurationExternal}
          />

          <Route exact path="/admin/configuracoes/cadastro-clube" component={BasicRegistration} />

          {/* Seu Clube */}
          <Route exact path="/admin/seu-clube" component={ClubTable} />

          <Route exact path="/admin/seu-clube/configuracoes/:id" component={ClubMenu} />
          <Route exact path="/admin/seu-clube/gerir-admin/:id" component={ClubManageAdmin} />

          {/* Rota de menu user */}
          <Route exact path="/admin/clube/seu-clube" component={ClubTable} />

          <Route exact path="/admin/seu-clube/:id" component={EditClub} />

          <Route exact path="/admin/seu-clube/campanhas/:id" component={Campaigns} />

          <Route
            exact
            path="/admin/configuracoes/campanhas/listarCampanhas/:id"
            component={ListCampaigns}
          />

          <Route exact path="/admin/seu-clube/relacionamento/:id" component={RelationshipClub} />

          <Route
            exact
            path="/admin/seu-clube/relacionamento/cadastro-usuarios/:id"
            component={RegistrationOptionClub}
          />

          <Route
            exact
            path="/admin/seu-clube/relacionamento/cadastra-lista-usuarios/:id"
            component={RegisterListUsersClub}
          />

          <Route
            exact
            path="/admin/seu-clube/relacionamento/lista-usuarios/:id"
            component={ListUsersClub}
          />

          <Route exact path="/admin/seu-clube/gerenciar-cards/:id" component={CardManagement} />

          <Route exact path="/admin/seu-clube/formulario/:id" component={FormSignUp} />

          <Route exact path="/admin/seu-clube/formulario-nps/:id" component={NpsFormSignUp} />

          <Route exact path="/admin/seu-clube/landing-page/:id" component={LandingPageList} />

          <Route
            exact
            path="/admin/seu-clube/cadastro-landing-page/:id"
            component={LandingPageSignUp}
          />

          <Route exact path="/admin/seu-clube/permissoes-acesso/:id" component={AccessPermission} />

          <Route
            exact
            path="/admin/seu-clube/permissoes-acesso/editar-whitelist/:id"
            component={EditWhitelist}
          />

          <Route
            exact
            path="/admin/seu-clube/permissoes-acesso/cadastrar-lote-whitelist/:id"
            component={AttachWhitelist}
          />

          <Route exact path="/admin/seu-clube/palavras-proibidas/:id" component={ForbiddenWords} />

          {/* SEGMENTAÇÃO */}

          <Route exact path="/admin/seu-clube/segmentacao/:id" component={SegmentationList} />

          <Route
            exact
            path="/admin/seu-clube/segmentacao/lista-usuarios/:id"
            component={MemberManagement}
          />

          <Route
            exact
            path="/admin/seu-clube/segmentacao/lista-recursos/:id"
            component={ResourceManagement}
          />

          <Route
            exact
            path="/admin/seu-clube/segmentacao/adicionar-segmento/:id"
            component={AddSegmentation}
          />

          <Route
            exact
            path="/admin/seu-clube/segmentacao/editar-segmento-pessoa/:id"
            component={AddSegmentation}
          />

          <Route
            exact
            path="/admin/seu-clube/segmentacao/editar-segmento-recurso/:id"
            component={AddSegmentation}
          />

          <Route
            exact
            path="/admin/seu-clube/segmentacao/regras-segmento-pessoa/:id"
            component={Rules}
          />

          <Route
            exact
            path="/admin/seu-clube/segmentacao/regras-segmento-recurso/:id"
            component={Rules}
          />

          {/* Aprovação estabelecimentos */}
          <Route
            exact
            path="/admin/aprovar/estabelecimentos"
            component={EstablishmentApprovalList}
          />
          <Route
            exact
            path="/admin/aprovado/estabelecimentos"
            component={EstablishmentApprovedList}
          />
          <Route
            exact
            path="/admin/aprovacao/estabelecimentos"
            component={EstablishmentEditApproval}
          />
          {/* Aprovação promoções */}
          <Route exact path="/admin/aprovar/promocoes" component={StoreApprovalList} />
          <Route exact path="/admin/aprovado/promocoes" component={StoreApprovedList} />
          <Route exact path="/admin/aprovacao/promocao" component={StorePromotionExternal} />
          <Route exact path="/admin/aprovacao/promocaofidelidade" component={StoreGroupExternal} />
          {/* Promotion Templates  */}

          {/* Simple */}
          <Route
            exact
            path="/admin/estabelecimento/:id/promotions/simple"
            component={PromotionTemplate}
          />
          <Route
            exact
            path="/admin/estabelecimento/:id/promotions/simple/add"
            component={PromotionForm}
          />
          {/* loyalty */}
          <Route
            exact
            path="/admin/estabelecimento/:id/promotions/loyalty"
            component={PromotionTemplate}
          />
          <Route
            exact
            path="/admin/estabelecimento/:id/promotions/loyalty/add"
            component={PromotionForm}
          />

          {/* PAGINAS EXTERNAS */}
          <Route exact path="/estabelecimento/:id" component={EstablishmentScreen} />
          <Route exact path="/estabelecimento/:id/resultados" component={ResultsScreen} />
          <Route exact path="/estabelecimento/:id/atendimento" component={AttendanceScreen} />
          <Route exact path="/estabelecimento/:id/atendimento/cliente" component={ClientScreen} />
          <Route exact path="/estabelecimento/novo/adicionar" component={EstablishmentCreateNew} />

          <Route exact path="/estabelecimento/:id/editar" component={EstablishmentEdit} />
          <Route
            exact
            path="/estabelecimento/:id/administradores"
            component={AdminConfigurationExternal}
          />
          <Route exact path="/estabelecimento/:id/suporte" component={ContactTriiboExternal} />
          <Route
            exact
            path="/estabelecimento/:id/promocao/adicionar"
            component={StorePromotionExternal}
          />

          <Route
            exact
            path="/estabelecimento/:id/promocao/adicionar/"
            component={StorePromotionExternal}
          />
          <Route
            exact
            path="/estabelecimento/:id/promocao/:promoId/editar"
            component={StorePromotionExternal}
          />
          <Route
            exact
            path="/estabelecimento/:id/promocaofidelidade/adicionar"
            component={StoreGroupExternal}
          />
          <Route
            exact
            path="/estabelecimento/:id/promocaofidelidade/editar"
            component={StoreGroupExternal}
          />
          <Route
            exact
            path="/estabelecimento/:id/promocoes/qrcode/generate"
            component={QRCodeGenerate}
          />
          <Route
            exact
            path="/estabelecimento/:id/promotions/simple"
            component={PromotionTemplate}
          />
          <Route
            exact
            path="/estabelecimento/:id/promotions/simple/add"
            component={PromotionForm}
          />
          <Route
            exact
            path="/estabelecimento/:id/promotions/loyalty"
            component={PromotionTemplate}
          />
          <Route
            exact
            path="/estabelecimento/:id/promotions/loyalty/add"
            component={PromotionForm}
          />
          <Route exact path="/estabelecimento/:id/comprarcreditos/" component={indexCredits} />
          <Route exact path="/estabelecimento/:id/relacionamento/" component={RelationshipScreen} />
          <Route
            exact
            path="/estabelecimento/:id/relacionamento/cadastro-usuarios"
            component={RegistrationOption}
          />

          <Route
            exact
            path="/estabelecimento/:id/relacionamento/cadastro-usuarios/step"
            component={RegistrationOption}
          />

          <Route
            exact
            path="/estabelecimento/:id/relacionamento/cadastra-lista-usuarios/"
            component={RegisterListUsers}
          />

          <Route
            exact
            path="/estabelecimento/:id/relacionamento/cadastra-lista-usuarios/step"
            component={RegisterListUsers}
          />
          <Route
            exact
            path="/estabelecimento/:id/relacionamento/lista-usuarios"
            component={ListUsers}
          />

          <Route
            exact
            path="/admin/configuracoes/enviaNotificacoes"
            component={SendCommunication}
          />

          <Route
            exact
            path="/estabelecimento/relacionamento/envio-comunicacoes/:id"
            component={SendCommunication}
          />

          <Route
            exact
            path="/estabelecimento/relacionamento/lista-comunicacoes/:id"
            component={ListCommunications}
          />

          <Route
            exact
            path="/estabelecimento/relacionamento/envio-postagem"
            component={SendPosts}
          />
          <Route path="/estabelecimento/relacionamento/envio-postagem/:id" component={SendPosts} />

          <Route
            exact
            path="/estabelecimento/relacionamento/lista-postagem"
            component={ListPosts}
          />

          <Route
            exact
            path="/admin/seu-clube/relacionamento/lista-postagem/:id"
            component={ListPosts}
          />

          <Route
            exact
            path="/estabelecimento/relacionamento/lista-postagem/:id"
            component={ListPosts}
          />

          <Route
            exact
            path="/admin/seu-clube/relacionamento/envio-postagem/"
            component={SendPosts}
          />

          <Route
            exact
            path="/admin/seu-clube/relacionamento/envio-postagem/:id"
            component={SendPosts}
          />

          <Route exact path="/estabelecimento/:id/promover/" component={Promote} />
          <Route exact path="/estabelecimento/:id/marketing/" component={MarketingScreen} />
          <Route
            exact
            path="/estabelecimento/:id/promover/links-uteis"
            component={RegisterUsefullLinks}
          />
          <Route exact path="/admin/faq/" component={FAQ} />
          <Route exact path="/estabelecimento/:id/passos" component={StepsConcludedPage} />

          <Route exact path="/estabelecimento/:id/secondStep" component={SecondStepConcludedPage} />

          <Route exact path="/estabelecimento/:id/thirdStep" component={ThirdStepConcludedPage} />

          <Route path="*" component={NotFound} />
        </Switch>
      </div>
    </>
  );
};

export default withRouter(AdminRouter);
