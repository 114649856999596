import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// STYLES
import * as S from '../../Establishment/ListEstablishment/styles';
import * as D from 'js/components/DesignSystem/styles';
import * as E from 'js/components/DesignSystem/Table/styles';
import { AreaHeader, GoBack } from 'js/components/Configurations/CreateClub/styles';
import { Button, CircularProgress, Pagination } from '@mui/material';
import { ContainerButtons, DeleteUserButton } from './segmentStyles';

// ICONES
import { Add } from '@mui/icons-material';
import { MdArrowBackIosNew } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';

// COMPONENTES
import { ModalNewMember } from './ModalNewMember';
import { ModalDelete } from './ModalDelete';
import MemberTable from './MemberTable';
import usePagination from 'js/components/TriiboComponents/Tables/usePagination';

// APIS
import { userSegmentBlackList } from 'js/library/utils/API/userSegmentBlackList';
import { getListClients } from 'js/library/utils/API/getListClients';
import { GetPartnerUserForms } from 'js/library/utils/API/getPartnerUserById';

export function MemberManagement(props) {
  const id = document.location.pathname.split('/')[5];
  const type = props.location.state.type;
  const segmentId = props.match.params.id;
  const partnerIdClube = localStorage.getItem('partnerIdClube');

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [uidMember, setUidMember] = useState('');
  const [isChange, setIsChange] = useState(false);

  const [columnsTableList, setColumnsTableList] = useState([]);
  const [listClients, setListClients] = useState([]);

  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);

  const PER_PAGE = 10;
  const count = Math.ceil(listClients.length / PER_PAGE);
  const _DATA = usePagination(listClients, PER_PAGE);

  const handleChangePage = (e, p) => {
    _DATA.jump(p);
    setPage(p);
  };

  useEffect(() => {
    const listColumnsAndClients = async () => {
      setLoading(true);

      const payload = {
        from: 0,
        size: 50,
      };

      try {
        // Colunas
        const columnsResponse = await GetPartnerUserForms(partnerIdClube);
        const columns = columnsResponse.formFields.map((field) => ({
          heading: field.fieldName,
          value: field.fieldName,
        }));
        setColumnsTableList(columns);

        // Membros
        const clientsResponse = await getListClients(segmentId, payload);
        const formattedClients = clientsResponse.map(formatClient);
        setListClients(formattedClients);
        setLoading(false);

        if (listClients?.length === 0) {
          setIsEmpty(true);
        }
      } catch (error) {
        console.error('Erro ao buscar colunas ou membros: ', error);
        setLoading(false);
      }
    };
    listColumnsAndClients();
  }, [isChange]);

  // Paginação: trás mais 50 membros ao clicar na ultima página da tabela
  useEffect(() => {
    async function fetchData() {
      const payload = {
        from: listClients.length,
        size: listClients.length + 50,
      };

      if (count === page && listClients.length >= 50) {
        setLoading(true);
        try {
          const clientsResponse = await getListClients(segmentId, payload);
          const formattedClients = clientsResponse.map(formatClient);
          setListClients((prev) => [...prev, ...formattedClients]);
        } catch (error) {
          console.error('Erro ao buscar mais membros: ', error);
          setLoading(false);
        } finally {
          setLoading(false);
        }
      }
    }
    fetchData();
  }, [page]);

  // Formata os campos disponiveis
  const formatClient = (client) => {
    const formattedClient = {
      partnerId: client.partnerId,
      partnerName: client.partnerName,
    };

    // Formata a data se estiver disponível
    if (client.data) {
      const dateInMillis = client.data;
      const formattedDate = new Date(dateInMillis).toLocaleDateString('pt-BR').replace(/\//g, '/');
      formattedClient.data = formattedDate;
    }

    // Adiciona documentos se estiverem disponíveis
    if (client.documentList) {
      client.documentList.forEach((doc) => {
        formattedClient[doc.type] = doc.value;
      });
    }

    // Adiciona contatos se estiverem disponíveis
    if (client.contactList) {
      client.contactList.forEach((contact) => {
        formattedClient[contact.type] = contact.value;
      });
    }

    // Adiciona endereço se estiver disponível
    if (client.addressList && client.addressList.endereco) {
      formattedClient.endereco = client.addressList.endereco;
    } else if (client.addressList) {
      const address = client.addressList
        .map(
          (addr) =>
            `${addr.streetAve}, ${addr.streetNumber}, ${addr.neighborhood}, ${addr.city}, ${addr.state}, 'cep:' ${addr.zipCode}, ${addr.extra}`
        )
        .join(', ');
      formattedClient.endereco = address;
    }

    // Adiciona todas as outras propriedades do cliente, garantindo que todas sejam exibidas
    Object.keys(client).forEach((key) => {
      if (!formattedClient.hasOwnProperty(key)) {
        // Tratamento para campos booleanos
        formattedClient[key] =
          typeof client[key] === 'boolean' ? (client[key] ? 'Sim' : 'Não') : client[key];
      }
    });

    // Substitui valores ausentes por 'Não cadastrado'
    Object.keys(formattedClient).forEach((key) => {
      if (!formattedClient[key]) {
        formattedClient[key] = 'Não cadastrado';
      }
    });

    return formattedClient;
  };

  const deleteMember = async () => {
    try {
      setLoadingDelete(true);
      const payload = {
        userSegmentId: id,
        uid: uidMember,
      };

      if (!type) {
        await userSegmentBlackList(payload);
        toast.success('Membro removido da lista.', { autoClose: 2000 });
        setOpenModalDelete(false);
        setIsChange(!isChange); // ATUALIZA TABELA DE MEMBROS.
      }
    } catch (err) {
      toast.error('Ocorreu um erro ao tentar remover o membro!', { autoClose: 2500 });
      console.error('Ocorreu um erro ao tentar remover o membro, ERRO:', err);
    } finally {
      setLoadingDelete(false);
    }
  };

  const handleOpenModalDelete = (selectedMember) => {
    setUidMember(selectedMember.uid);
    setOpenModalDelete(!openModalDelete);
  };

  const handleOpenModalAdd = () => {
    setOpenModalAdd(!openModalAdd);
  };

  const componentReceiver = (column, childrens) => {
    return {
      columnComponent: (
        <>
          <E.TableTH>Excluir</E.TableTH>
        </>
      ),
      childrenComponent:
        childrens === null ? null : (
          <>
            <E.TableTD>
              <DeleteUserButton onClick={() => handleOpenModalDelete(childrens)}>
                <FaTrash style={{ color: 'white' }} />
              </DeleteUserButton>
            </E.TableTD>
          </>
        ),
    };
  };

  return (
    <S.Container>
      <AreaHeader
        style={{
          color: '#08bad0',
          justifyContent: 'end',
          padding: '1rem',
          marginLeft: '3rem',
        }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <D.TriiboH1
        style={{
          textAlign: 'center',
        }}
      >
        Membros
      </D.TriiboH1>

      <ContainerButtons>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleOpenModalAdd}
        >
          Novo membro
        </Button>
      </ContainerButtons>

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={30} />
        </div>
      ) : (
        _DATA.currentData() && (
          <>
            <MemberTable
              data={_DATA.currentData()}
              column={columnsTableList}
              perPage={PER_PAGE}
              usePaginate={false}
              componentReceiver={componentReceiver}
              isEmpty={isEmpty}
              loading={loading}
            />
            <Pagination
              sx={{ margin: '0 auto', display: listClients.length === 0 && 'none' }}
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              color="secondary"
              onChange={handleChangePage}
            />
          </>
        )
      )}

      <ModalDelete
        close={handleOpenModalDelete}
        open={openModalDelete}
        deleteType={deleteMember}
        loading={loadingDelete}
      />

      <ModalNewMember
        setOpenModalAdd={setOpenModalAdd}
        open={openModalAdd}
        userSegmentId={id}
        attApi={setIsChange}
        isChangeApi={isChange}
        data={listClients}
      />
    </S.Container>
  );
}

