import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as S from '../../../components/Establishment/styles';
import * as D from 'js/components/DesignSystem/styles';

import {
  colors,
  Paper,
  Select,
  Badge,
  TextField,
  Input,
  Button,
  Grid,
  Chip,
  Typography,
  IconButton,
  FormLabel,
  FormControlLabel,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Snackbar,
  Tooltip,
  Modal,
  Switch,
  Box,
  CircularProgress,
} from '@mui/material';
import { AddPhotoAlternate, Send, ArrowBack, Close, Help } from '@mui/icons-material';
import CropFreeIcon from '@mui/icons-material/CropFree';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { withStyles } from '@mui/styles';

import placeholder from 'styles/assets/placeholder/placeholder_triibo.png';
import thumbnailPromocao from 'styles/assets/promocao/thumbnail-promocao.png';
import listaFotos from 'styles/assets/promocao/listaFotos-promocao.png';

import Loading from 'js/containers/Loading/Loading';
import { Cropper } from 'react-image-cropper';
import PropTypes from 'prop-types';
import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';
import Downshift from 'downshift';
import deburr from 'lodash/deburr';

import { updateStoreBusinessPartnerAction } from 'js/core/actions/storeBusinessPartnerActions';
import {
  downloadImage,
  downloadImageAsBlob,
  uploadImage,
} from 'js/library/services/StorageManager';
import { convertToDate } from 'js/library/utils/helpers';
import { verificaPerfil } from 'js/library/services/AuthenticationManager';
import { b64toBlob, verificarAprovacaoPromocao } from 'js/library/services/DatabaseManager';
import { getUserInfo_v1 } from 'js/library/utils/API/getUserInfo_v1';
import { findValueInArray, getUserInfo, isAdminPage } from 'js/library/utils/helpers.js';
import EditShowId from 'js/components/Store/Promotion/EditShowId';
import { BsXLg } from 'react-icons/bs';

import { getStoreChangeRequest } from 'js/library/utils/API/Store/apiGetStoreChangeRequest';
import { ToastContainer, toast } from 'react-toastify';
import { confirmStoreChanges } from 'js/library/utils/API/Store/apiConfirmStoreChanges';
import { updateStore, updateStoreApprovals } from 'js/library/utils/API/Store/apiUpdateStore';
import getEstablishmentId from 'js/library/utils/API/Establishment/apiReadEstablishment';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  root: {
    background: '#ffffff',
    borderRadius: 5,
    border: '1px solid #6c6c6c',
    color: 'white',
  },
});

function getModalStyle() {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: 'white',
    padding: '30px',
    width: '94%',
    height: '50%',
    maxWidth: '600px',
    maxHeight: '450px',
    transform: 'translate(-50%, -50%)',
  };
}

let suggestions = [];

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function getSuggestions(value) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter((suggestion) => {
        const keep =
          count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

// Estilo da notificação - Badge
const StyledBadge = withStyles((theme) => ({
  badge: {
    fontSize: '12px',
    color: '#6c6c6c',
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    right: -2,
    top: 3,
    border: '1px solid #6c6c6c',
    backgroundColor: '#fff',
    padding: '0',

    '&:hover': {
      backgroundColor: colors['red'][700],
      color: 'white',
      cursor: 'pointer',
      border: 'none',
    },
  },
}))(Badge);

const StyledBadge2 = withStyles((theme) => ({
  badge: {
    fontSize: '12px',
    color: '#6c6c6c',
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    right: -2,
    top: 3,
    border: '1px solid #6c6c6c',
    backgroundColor: '#fff',
    padding: '0',
  },
}))(Badge);

class StoreBusinessPartnerExternal extends Component {
  constructor(props, context) {
    super(props, context);

    window.pathname = null;

    const establishmentId = props.match.params.id;

    const urlEdit = window.location.href;
    const hasEditWord = urlEdit.includes('/editar');

    this.dayMs = 86400000;
    this.responseDays = 14; //Tempo de espera para aprovação em dias

    //convertendo enable date
    let enableDate = new Date(Date.now());
    if (props.location.state.enableDate !== undefined) {
      enableDate = new Date(props.location.state.enableDate);
    }

    const enableYear = enableDate.getFullYear();
    const enableMonth =
      enableDate.getMonth() + 1 < 10
        ? '0' + (enableDate.getMonth() + 1)
        : enableDate.getMonth() + 1;
    const enableDay = enableDate.getDate() < 10 ? '0' + enableDate.getDate() : enableDate.getDate();
    const enableHour =
      enableDate.getHours() < 10 ? '0' + enableDate.getHours() : enableDate.getHours();
    const enableMinute =
      enableDate.getMinutes() < 10 ? '0' + enableDate.getMinutes() : enableDate.getMinutes();

    enableDate =
      enableYear + '-' + enableMonth + '-' + enableDay + 'T' + enableHour + ':' + enableMinute;

    //convertendo disable date
    let disableDate = new Date(Date.now() + 86400000 * 365);
    if (props.location.state.disableDate !== undefined) {
      disableDate = new Date(props.location.state.disableDate);
    }

    const disableYear = disableDate.getFullYear();
    const disableMonth =
      disableDate.getMonth() + 1 < 10
        ? '0' + (disableDate.getMonth() + 1)
        : disableDate.getMonth() + 1;
    const disableDay =
      disableDate.getDate() < 10 ? '0' + disableDate.getDate() : disableDate.getDate();
    const disableHour =
      disableDate.getHours() < 10 ? '0' + disableDate.getHours() : disableDate.getHours();
    const disableMinute =
      disableDate.getMinutes() < 10 ? '0' + disableDate.getMinutes() : disableDate.getMinutes();

    disableDate =
      disableYear + '-' + disableMonth + '-' + disableDay + 'T' + disableHour + ':' + disableMinute;

    this.state = {
      //dados do template
      id: null,
      isPublished: false,
      level: 99,
      detailsTitle: null,
      detailsDescription: null,
      title: null,
      description: null,
      dueDate: null,
      enableDate: null,
      disableDate: null,
      url: null,
      region: 'local',
      droidWebView: false,
      iOSWebView: false,
      type: 'businessPartner',

      keywordsSetConsumption: '',
      keywords: '',
      keywordsDownload: null,
      keywordsSetConsumptionList: [],
      keywordsList: [],
      tags: [],

      tagCapture: '',
      disableButtonUpdate: true,
      messageBlockedKw: '',

      thumbnail: null,
      photo: null,

      //loadedImageThumbnail: props.location.state.thumbnail === undefined ? null : props.location.state.downloadedThumb,
      loadedImageThumbnail: null,
      loadedImagePhoto: null,

      thumbnailFile: null,
      photoFile: null,

      OldThumbnail: null,
      Oldphoto: null,

      buttonLabel: '',
      buttonDescription: '',
      showButtonDescription: false,
      goToPlaceLabel: '',
      showGoToPlace: false,

      campanhaTriibo: false,

      goBack: false,
      indexCrop: 0,
      open: false,
      openCheck: false,
      vertical: 'top',
      horizontal: 'right',
      openModal: false,
      loading: false,

      oldPhotoList: null,
      photoListFile: null,
      loadedPhotoList: null,

      //mensagem de aprovação
      newStatus: null,
      messageApproval: null,

      checkoutCode: null,
      wherePromo: null,
      editPage: urlEdit.includes('/editar'),
      action: null,
      status: '',
      comment: '',

      //dados do estabelecimento
      establishmentId: '',
      establishmentName: '',
      lat: null,
      long: null,
      dataEstabelecimento: null,
    };

    (async () => {
      try {
        const result = await getEstablishmentId(establishmentId);

        this.setState({
          establishmentId: result.id ?? establishmentId,
          establishmentName: result.nome,
          lat: result.lat,
          long: result.long,
          dataEstabelecimento: result,
        });
      } catch (error) {
        console.error('Erro ao obter dados do estabelecimento', error);
      }
    })();

    //extrai o id da promoção da url
    function extractIdPromotionUrl() {
      const url = window.location.href;
      const regex = /\/promocaoexterna\/([^/]+)\/editar\//;
      const match = url.match(regex);

      if (match && match.length > 1) {
        const promoId = match[1];
        return promoId;
      } else {
        return null;
      }
    }

    if (hasEditWord) {
      const promoId = extractIdPromotionUrl();

      getStoreChangeRequest(promoId).then((dataReceived) => {
        const promoData = dataReceived.response;

        //storeInfo
        const storeInfo = promoData.storeInfo;

        //storeApprovals
        const dataApprovals = promoData.storeApprovals;
        let storeApprovals;

        // Verifica se dataApprovals está definido e não vazio
        if (dataApprovals && Object.keys(dataApprovals).length > 0) {
          // Pega o primeiro elemento do objeto dataApprovals
          const firstApprovalKey = Object.keys(dataApprovals)[0];
          storeApprovals = dataApprovals[firstApprovalKey];
        }

        //storeEdits
        const dataStoreEdits = promoData.storeEdits;
        let storeEdits = '';

        if (dataStoreEdits) {
          Object.values(dataStoreEdits).forEach((edits) => {
            storeEdits = edits;
          });
        }

        const mergeFields = (info, edits) => {
          return {
            ...info,
            ...edits,
          };
        };

        // Finalizando o estado com os valores atualizados de storeEdits
        const finalState = mergeFields(storeInfo, storeEdits.changedFields || {});

        this.setState({
          action: storeApprovals?.action,
          status: storeApprovals?.status,
          comment: storeApprovals?.comment,

          id: promoId,
          isPublished: finalState.isPublished,
          level: finalState.level,
          detailsTitle: finalState.detailsTitle,
          detailsDescription: finalState.detailsDescription,
          title: finalState.title,
          description: finalState.description,
          dueDate: convertToDate(finalState.dueDate),
          enableDate: convertToDate(finalState.enableDate),
          disableDate:
            finalState.disableDate !== undefined
              ? convertToDate(finalState.disableDate)
              : finalState.dueDate !== undefined
              ? convertToDate(finalState.dueDate)
              : null,
          url: finalState.url,
          region: finalState.region,
          droidWebView: finalState.droidWebView,
          iOSWebView: finalState.iOSWebView,
          type: finalState.type,

          keywordsSetConsumptionList: finalState.keyWordsSetConsumption ?? [],
          keywordsList: finalState.keyWordsSet ?? [],
          tags: finalState.tags ?? [],

          messageBlockedKw: finalState.message,

          thumbnail: finalState.thumbnail,
          photo: finalState.photo,

          loadedImageThumbnail: finalState.thumbnail,
          loadedImagePhoto: finalState.photo,

          buttonLabel: finalState.buttonLabel,
          buttonDescription: finalState.buttonDescription,
          showButtonDescription: finalState.showButtonDescription,
          goToPlaceLabel: finalState.goToPlaceLabel,
          showGoToPlace: finalState.showGoToPlace,

          campanhaTriibo: finalState.campanhaTriibo,

          oldPhotoList: finalState.photoList,

          checkoutCode: finalState.checkoutCode,
          wherePromo: localStorage.getItem('fromWherePromotion'),
        });

        if (finalState.photoList !== undefined) {
          let photoListFile = [];

          Object.keys(finalState.photoList).map((item, i) =>
            downloadImage('promocao', finalState.photoList[i]).then((downloadedImage) => {
              photoListFile[i] = downloadedImage;
              this.setState({ photoListFile });
            })
          );
        }

        const folderThumb =
          finalState.type === 'promotion' && finalState.thumbnail !== undefined
            ? finalState.thumbnail.split('/')[0]
            : 'promocao';

        downloadImage(folderThumb, finalState.thumbnail)
          .then((downloadedImage) => {
            this.setState({
              loadedImageThumbnail: downloadedImage,
            });
          })
          .catch(() => {
            // console.log("Foto não encontrada:", storeInfo.thumbnail);
          });

        if (finalState.photo !== undefined) {
          downloadImage('promocao', finalState.photo).then((loaded) => {
            this.setState({
              loadedImagePhoto: loaded,
            });
          });
        }
      });
    }

    // Verifica super admin
    verificaPerfil(findValueInArray(getUserInfo().contactList, 'type', 'cellPhone').value).then(
      (result) => {
        this.setState({ superUser: result });
      }
    );

    // Verifica aprovação
    if (this.state.id) {
      verificarAprovacaoPromocao(
        this.state.id,
        this.state.type,
        this.state.establishmentId,
        this.state.establishmentName
      )
        .then((result) => {
          this.setState({ aprovacao: result });

          // Pega nome do aprovador
          if (result.approverId && result.approverId !== '') {
            let userInfo = {
              uId: result.approverId,
            };
            getUserInfo_v1(['getUserInfo'], userInfo).then((user) => {
              if (
                user.data &&
                user.data.success &&
                user.data.success.userInfo &&
                user.data.success.userInfo.name
              ) {
                this.setState({
                  approverName: user.data.success.userInfo.name,
                });
              }
            });
          }
        })
        .catch((error) => {
          // console.log("Erro ao verificar aprovação", error);
        });
    }
  }

  //altera os states dos campos, caso o usuário insira informações nos input
  handleChange = (name) => (event) => {
    if (name === 'level') {
      this.setState({
        [name]:
          event.target.value > 99 || event.target.value < 1 ? this.state.level : event.target.value,
        openCheck: event.target.value > 99 || event.target.value < 1 ? true : false,
        messageAlert:
          event.target.value > 99 || event.target.value < 1
            ? 'O nível da hierarquia deve ser entre 1 e 99.'
            : null,
      });
    } else {
      this.setState({
        [name]: event.target.value,
        goBack: true,
      });
    }
  };

  //handle Modal
  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  fileChangedHandler = (name, oldName) => (event) => {
    this.setState({ [oldName]: this.state[name] });

    if (event.target.files[0] !== undefined) {
      const data = [URL.createObjectURL(event.target.files[0])];
      this.setState({ imageToCrop: data, imageCroped: name, openModal: true });
    }
  };

  //IMAGENS -------------->
  photoListChangedHandler = (event) => {
    let listImages = [];

    for (let index = 0; index < event.target.files.length; index++) {
      listImages[index] = URL.createObjectURL(event.target.files[index]);
    }

    if (event.target.files !== undefined) {
      this.setState({
        openModal: true,
        imageToCrop: listImages,
        imageCroped: 'photoListFile',
      });
    }
  };

  cropImage(state) {
    //campo onde a image está sendo inserido
    const newCrop = this.state.imageCroped;

    //imagem recebida do crop
    let node = this[state].crop();

    this.setState({
      [newCrop]: node,
      imageToCrop: null,
      goBack: true,
      openModal: false,
    });
  }

  photoListCropImage(state) {
    //campo onde a image está sendo inserido
    const newCrop = this.state.imageCroped;
    //campo utilizado para saber se a imagem é a ultima do cadastro
    const endArray = this.state.imageToCrop[this.state.indexCrop + 1];

    //imagem recebida do crop
    let node = this[state].crop();

    //verificando se é um array de imagens
    let data = [];
    if (this.state[newCrop] !== null) {
      //const a = this.state.indexCrop === 0 ? [] : this.state[newCrop];
      let a = this.state[newCrop];
      data = a.concat(node);
    } else {
      data = [node];
    }

    this.setState({
      [newCrop]: this.state.imageCroped === 'photoListFile' ? data.slice(0, 4) : node,
      indexCrop: endArray === undefined ? 0 : this.state.indexCrop + 1,
      goBack: true,
      imageToCrop: endArray === undefined ? null : this.state.imageToCrop,
      openModal: endArray === undefined ? false : true,
    });
  }
  //IMAGENS---------------<

  handleChangeSwitch = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
      goBack: true,
    });
  };

  goBack = () => {
    if (this.state.goBack === false) {
      window.history.back();
    } else {
      this.handleClickOpen();
    }
  };

  //HANDLE DIALOGS
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  removeImage = (idx) => {
    if (this.state.photoListFile && idx < this.state.photoListFile.length) {
      let newPhotoList = this.state.photoListFile;
      newPhotoList.splice(idx, 1);
      this.setState({
        photoListFile: newPhotoList,
        disableButtonUpdate: false,
      });
    } else {
      // console.log("Imagem não existe");
    }
  };

  mainImage = (idx) => {
    let currentFoto = this.state.photoFile ? this.state.photoFile : this.state.loadedImagePhoto;
    let newPhotoListFile = this.state.photoListFile;
    let newFoto = this.state.photoListFile[idx];

    newPhotoListFile.splice(idx, 1);
    newPhotoListFile.unshift(currentFoto);

    this.setState({ photoListFile: newPhotoListFile, photoFile: newFoto });
  };

  handleTagCapture = (e) => {
    this.setState({ tagCapture: e.target.value });
  };

  handlePressEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handlePushTag();
    }
  };

  handlePushTag = (e) => {
    e.preventDefault();
    let tagHolder = this.state.tags;
    if (
      tagHolder.indexOf(this.state.tagCapture.toLocaleLowerCase()) === -1 &&
      this.state.tagCapture.length !== 0
    ) {
      tagHolder.push(this.state.tagCapture.toLowerCase());
    }
    this.setState({
      tags: [...tagHolder],
      tagCapture: '',
      disableButtonUpdate: false,
    });
  };

  //HANDLE ALERT
  checkInformation = (e) => {
    e.preventDefault();
    const thumbnail =
      this.state.thumbnailFile === null && this.state.loadedImageThumbnail === null ? true : false;
    const photo =
      this.state.photoFile === null && this.state.loadedImagePhoto === null ? true : false;
    if (this.state.tags.length < 1) {
      this.setState({
        openCheck: true,
        messageAlert: 'Obrigatorio adicionar pelo menos uma tag.',
      });
    } else if ((thumbnail || photo) && this.state.isPublished === true) {
      this.setState({
        openCheck: true,
        messageAlert: 'Todas as imagens devem ser inseridas.',
      });
    } else {
      this.setState({ loading: true });

      this.props.updateStoreBusinessPartnerComponent(
        this.state.id,
        this.state.isPublished,
        this.state.level,
        this.state.keywordsList,
        this.state.keywordsSetConsumptionList,
        this.state.messageBlockedKw,
        this.state.tags,
        this.state.detailsTitle,
        this.state.detailsDescription,
        this.state.title,
        this.state.description,
        this.state.establishmentId,
        this.state.establishmentName,
        this.state.lat,
        this.state.long,
        this.state.dueDate,
        this.state.enableDate,
        this.state.disableDate,
        this.state.url,
        this.state.photo,
        this.state.thumbnail,
        this.state.type,
        this.state.region,
        this.state.droidWebView,
        this.state.iOSWebView,
        this.state.thumbnailFile,
        this.state.OldThumbnail,
        this.state.photoFile,
        this.state.Oldphoto,
        this.state.buttonLabel,
        this.state.buttonDescription,
        this.state.showButtonDescription,
        this.state.goToPlaceLabel,
        this.state.showGoToPlace,
        this.state.oldPhotoList,
        this.state.photoListFile,
        this.state.campanhaTriibo,
        this.state.checkoutCode
      );
    }
  };

  handleCloseAlert = () => {
    this.setState({ openCheck: false });
  };

  //handle downshift
  handleChangeDownshiftConsume = (item) => {
    let { keywordsSetConsumptionList } = this.state;

    if (keywordsSetConsumptionList.indexOf(item) === -1) {
      keywordsSetConsumptionList = [...keywordsSetConsumptionList, item];
    }

    this.setState({
      keywordsSetConsumption: '',
      keywordsSetConsumptionList,
    });
  };

  handleDeleteDownshiftConsume = (item) => () => {
    this.setState((state) => {
      const keywordsSetConsumptionList = [...state.keywordsSetConsumptionList];
      keywordsSetConsumptionList.splice(keywordsSetConsumptionList.indexOf(item), 1);
      return { keywordsSetConsumptionList };
    });
  };

  handleChangeDownshift = (item) => {
    let { keywordsList } = this.state;

    if (keywordsList.indexOf(item) === -1) {
      keywordsList = [...keywordsList, item];
    }

    this.setState({
      keywords: '',
      keywordsList,
    });
  };

  handleDeleteDownshift = (item) => () => {
    this.setState((state) => {
      const keywordsList = [...state.keywordsList];
      keywordsList.splice(keywordsList.indexOf(item), 1);
      return { keywordsList };
    });
  };
  //fim handle downshift

  componentDidMount() {
    getDataListRestApi('Keywords')
      .then((dataReceived) => {
        let keywordsDownload = [];

        Object.values(dataReceived).map(function (item) {
          return keywordsDownload.push({ label: item.value });
        });

        suggestions = keywordsDownload;
      })
      .catch((error) => {
        // console.log("Erro na data:", error);
      });
  }

  //Função que aprova as promoções, dos tipos: edição e criação
  approvePromotion = async (e) => {
    e.preventDefault();

    const storageFolderStore = 'promocao';

    let currentDate = Date.now();

    if (this.state.photoFile !== null) {
      if (this.state.photoFile.indexOf('https') === 0) {
        this.state.photoFile = await downloadImageAsBlob(this.state.photoFile);
      } else {
        this.state.photoFile = b64toBlob(this.state.photoFile);
      }

      const photoName = 'storage:' + this.state.id + ':photo:' + currentDate;
      uploadImage(storageFolderStore, this.state.photoFile, photoName);
      this.state.photo = photoName;
    }

    if (this.state.thumbnailFile !== null) {
      if (this.state.thumbnailFile.indexOf('https') === 0) {
        this.state.thumbnailFile = await downloadImageAsBlob(this.state.thumbnailFile);
      } else {
        this.state.thumbnailFile = b64toBlob(this.state.thumbnailFile);
      }

      const thumbName = 'storage:' + this.state.id + ':thumbnail:' + currentDate;
      uploadImage(storageFolderStore, this.state.thumbnailFile, thumbName);
      this.state.thumbnail = thumbName;
    }

    let listaFotos = [];
    if (this.state.photoListFile !== null) {
      for (const key in this.state.photoListFile) {
        listaFotos[key] = 'gal' + key + '_' + this.state.id + ':' + currentDate;

        if (this.state.photoListFile[key].indexOf('https') === 0) {
          this.state.photoListFile[key] = await downloadImageAsBlob(this.state.photoListFile[key]);
        } else {
          this.state.photoListFile[key] = b64toBlob(this.state.photoListFile[key]);
        }
      }
    } else {
      listaFotos = this.state.oldPhotoList;
    }

    let pathnameArray = window.location.pathname.split('/');

    let pathname = '/';

    if (pathnameArray[1] === 'admin' && pathnameArray[2] === 'estabelecimento') {
      pathname = '/admin/estabelecimento/' + this.state.idEstabelecimento + '/ofertas';
    } else if (
      pathnameArray[1] === 'admin' &&
      pathnameArray[2] === 'aprovacao' &&
      pathnameArray[3] === 'promocao'
    ) {
      pathname = '/admin/aprovar/promocoes';
    } else {
      pathname = '/estabelecimento/' + this.state.idEstabelecimento + '/promocoes';
    }

    const userId = getUserInfo().uId;

    //dados da promoção
    const data = {
      id: this.state.id,
      isPublished: this.state.isPublished,
      level: this.state.level,
      keyWordsSet: this.state.keywordsList,
      keyWordsSetConsumption: this.state.keywordsSetConsumptionList,
      message: this.state.messageBlockedKw,
      tags: this.state.tags,
      detailsTitle: this.state.detailsTitle,
      detailsDescription: this.state.detailsDescription,
      title: this.state.title,
      description: this.state.description,
      establishmentId: this.state.establishmentId,
      establishmentName: this.state.establishmentName,
      lat: this.state.lat,
      long: this.state.long,
      dueDate: Date.parse(this.state.dueDate),
      enableDate: Date.parse(this.state.enableDate),
      disableDate: Date.parse(this.state.disableDate),
      url: this.state.url,
      photo: this.state.photo,
      thumbnail: this.state.thumbnail,
      type: this.state.type,
      region: this.state.region,
      droidWebView: this.state.droidWebView,
      iOSWebView: this.state.iOSWebView,
      photoList: listaFotos,
      buttonLabel: this.state.buttonLabel,
      buttonDescription: this.state.buttonDescription,
      showButtonDescription: this.state.showButtonDescription,
      goToPlaceLabel: this.state.goToPlaceLabel,
      showGoToPlace: this.state.showGoToPlace,
      campanhaTriibo: this.state.campanhaTriibo,
      checkoutCode: this.state.checkoutCode,
    };

    const emptyFields = [];

    if (!data.tags[0]) {
      emptyFields.push('Tags');
    }

    // if (!data.title) {
    //   emptyFields.push('Título');
    // }

    // if (!data.description) {
    //   emptyFields.push('Descrição');
    // }

    if (emptyFields.length === 1) {
      const errorMessage = `O seguinte campo ainda precisa ser preenchido: ${emptyFields[0]}`;
      toast.error(errorMessage);
    } else if (emptyFields.length > 1) {
      const errorMessage = `Os seguintes campos ainda precisam ser preenchidos: ${emptyFields.join(
        ', '
      )}`;
      toast.error(errorMessage);
    } else {
      if (this.state.action !== 'create') {
        // api para aprovar promoções do tipo de edição
        confirmStoreChanges(userId, this.state.id, true, this.state.comment, data).then(() => {
          this.props.history.push('/admin/aprovado/promocoes');
        });
        uploadImage(storageFolderStore, this.state.photoListFile, listaFotos);
      } else {
        // api para aprovar promoções do tipo de criação
        const payload = {
          status: 'aprovado',
          comment: this.state.comment ?? '',
        };

        if (emptyFields.length === 1) {
          const errorMessage = `O seguinte campo ainda precisa ser preenchido: ${emptyFields[0]}`;
          toast.error(errorMessage);
        } else if (emptyFields.length > 1) {
          const errorMessage = `Os seguintes campos ainda precisam ser preenchidos: ${emptyFields.join(
            ', '
          )}`;
          toast.error(errorMessage);
        } else {
          updateStoreApprovals(userId, this.state.id, payload, null).then(() => {
            this.props.history.push('/admin/aprovado/promocoes');
            //atualiza os dados da promoção
            updateStore(this.state.id, data, pathname);
          });

          uploadImage(storageFolderStore, this.state.photoListFile, listaFotos);
        }
      }
    }
  };

  //Função que reprova as promoções, dos tipos: edição e criação
  disapprovePromotion = async (e) => {
    e.preventDefault();
    if (this.state.comment === undefined || this.state.comment.trim() === '') {
      toast.error('A mensagem de avaliação é obrigatória!');
      return;
    }

    const storageFolderStore = 'promocao';

    let currentDate = Date.now();

    if (this.state.photoFile !== null) {
      if (this.state.photoFile.indexOf('https') === 0) {
        this.state.photoFile = await downloadImageAsBlob(this.state.photoFile);
      } else {
        this.state.photoFile = b64toBlob(this.state.photoFile);
      }

      const photoName = 'storage:' + this.state.id + ':photo:' + currentDate;
      uploadImage(storageFolderStore, this.state.photoFile, photoName);
      this.state.photo = photoName;
    }

    if (this.state.thumbnailFile !== null) {
      if (this.state.thumbnailFile.indexOf('https') === 0) {
        this.state.thumbnailFile = await downloadImageAsBlob(this.state.thumbnailFile);
      } else {
        this.state.thumbnailFile = b64toBlob(this.state.thumbnailFile);
      }

      const thumbName = 'storage:' + this.state.id + ':thumbnail:' + currentDate;
      uploadImage(storageFolderStore, this.state.thumbnailFile, thumbName);
      this.state.thumbnail = thumbName;
    }

    let listaFotos = [];
    if (this.state.photoListFile !== null) {
      for (const key in this.state.photoListFile) {
        listaFotos[key] = 'gal' + key + '_' + this.state.id + ':' + currentDate;

        if (this.state.photoListFile[key].indexOf('https') === 0) {
          this.state.photoListFile[key] = await downloadImageAsBlob(this.state.photoListFile[key]);
        } else {
          this.state.photoListFile[key] = b64toBlob(this.state.photoListFile[key]);
        }
      }
    } else {
      listaFotos = this.state.oldPhotoList;
    }

    let pathnameArray = window.location.pathname.split('/');

    let pathname = '/';

    if (pathnameArray[1] === 'admin' && pathnameArray[2] === 'estabelecimento') {
      pathname = '/admin/estabelecimento/' + this.state.idEstabelecimento + '/ofertas';
    } else if (
      pathnameArray[1] === 'admin' &&
      pathnameArray[2] === 'aprovacao' &&
      pathnameArray[3] === 'promocao'
    ) {
      pathname = '/admin/aprovar/promocoes';
    } else {
      pathname = '/estabelecimento/' + this.state.idEstabelecimento + '/promocoes';
    }

    const userId = getUserInfo().uId;

    //dados da promoção
    const data = {
      id: this.state.id,
      isPublished: this.state.isPublished,
      level: this.state.level,
      keyWordsSet: this.state.keywordsList,
      keyWordsSetConsumption: this.state.keywordsSetConsumptionList,
      message: this.state.messageBlockedKw,
      tags: this.state.tags,
      detailsTitle: this.state.detailsTitle,
      detailsDescription: this.state.detailsDescription,
      title: this.state.title,
      description: this.state.description,
      establishmentId: this.state.establishmentId,
      establishmentName: this.state.establishmentName,
      lat: this.state.lat,
      long: this.state.long,
      dueDate: Date.parse(this.state.dueDate),
      enableDate: Date.parse(this.state.enableDate),
      disableDate: Date.parse(this.state.disableDate),
      url: this.state.url,
      photo: this.state.photo,
      thumbnail: this.state.thumbnail,
      type: this.state.type,
      region: this.state.region,
      droidWebView: this.state.droidWebView,
      iOSWebView: this.state.iOSWebView,
      photoList: listaFotos,
      buttonLabel: this.state.buttonLabel,
      buttonDescription: this.state.buttonDescription,
      showButtonDescription: this.state.showButtonDescription,
      goToPlaceLabel: this.state.goToPlaceLabel,
      showGoToPlace: this.state.showGoToPlace,
      campanhaTriibo: this.state.campanhaTriibo,
      checkoutCode: this.state.checkoutCode,
    };

    const emptyFields = [];

    if (!data.tags[0]) {
      emptyFields.push('Tags');
    }

    // if (!data.title) {
    //   emptyFields.push('Título');
    // }

    // if (!data.description) {
    //   emptyFields.push('Descrição');
    // }

    if (emptyFields.length === 1) {
      const errorMessage = `O seguinte campo ainda precisa ser preenchido: ${emptyFields[0]}`;
      toast.error(errorMessage);
    } else if (emptyFields.length > 1) {
      const errorMessage = `Os seguintes campos ainda precisam ser preenchidos: ${emptyFields.join(
        ', '
      )}`;
      toast.error(errorMessage);
    } else {
      if (this.state.action !== 'create') {
        // api para reprovar promoções do tipo de edição
        confirmStoreChanges(userId, this.state.id, false, this.state.comment, data).then(() => {
          this.props.history.push('/admin/aprovado/promocoes');
        });
        uploadImage(storageFolderStore, this.state.photoListFile, listaFotos);
      } else {
        // api para reprovar promoções do tipo de criação
        const payload = {
          status: 'negado',
          comment: this.state.comment,
        };
        if (emptyFields.length === 1) {
          const errorMessage = `O seguinte campo ainda precisa ser preenchido: ${emptyFields[0]}`;
          toast.error(errorMessage);
        } else if (emptyFields.length > 1) {
          const errorMessage = `Os seguintes campos ainda precisam ser preenchidos: ${emptyFields.join(
            ', '
          )}`;
          toast.error(errorMessage);
        } else {
          updateStoreApprovals(userId, this.state.id, payload, null).then(() => {
            this.props.history.push('/admin/aprovado/promocoes');
            //atualiza os dados da promoção
            updateStore(this.state.id, data, pathname);
          });
          uploadImage(storageFolderStore, this.state.photoListFile, listaFotos);
        }
      }
    }
  };

  render = () => {
    const { classes } = this.props;
    const keywords = this.state.keywords;
    const keywordsConsume = this.state.keywordsSetConsumption;
    const selectedItem = this.state.keywordsList;
    const selectedItemConsume = this.state.keywordsSetConsumptionList;

    let fotos = [];
    for (let i = 0; i < 4; i++) {
      let exists =
        (this.state.photoListFile && this.state.photoListFile[i]) ||
        (this.state.loadedPhotoList && this.state.loadedPhotoList[i]);
      fotos.push(
        <Grid key={i} item md={10} style={{ marginBottom: '15px' }}>
          <StyledBadge
            badgeContent={
              exists ? (
                <Tooltip title="Excluir">
                  <Close
                    style={{ width: '100%', height: '100%' }}
                    onClick={() => this.removeImage(i)}
                  />
                </Tooltip>
              ) : (
                ''
              )
            }
            invisible={!exists}
          >
            <Tooltip title={exists ? 'Tornar principal' : 'Utilize o botão "Adicionar fotos"'}>
              <img
                style={{ cursor: exists ? 'pointer' : 'default' }}
                onClick={exists ? () => this.mainImage(i) : null}
                src={
                  this.state.photoListFile && this.state.photoListFile[i]
                    ? this.state.photoListFile[i]
                    : this.state.loadedPhotoList && this.state.loadedPhotoList[i]
                    ? this.state.loadedPhotoList[i]
                    : placeholder
                }
                width="100%"
                height="100%"
                alt="Lista de Fotos"
              />
            </Tooltip>
          </StyledBadge>
        </Grid>
      );
    }

    if (this.props.loading && this.state.loading) {
      return (
        <div className="loading">
          <Loading />
        </div>
      );
    } else {
      if (window.pathname !== null) {
        return (
          <Redirect
            to={{
              pathname: window.pathname,
              state: this.state.dataEstabelecimento,
            }}
          />
        );
      } else {
        return this.state.editPage === true && this.state.title === null ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '100vh',
              alignItems: 'center',
            }}
          >
            <CircularProgress size="100px" />
          </div>
        ) : (
          <div
            style={{
              padding: '30px 50px',
              maxWidth: '100%',
            }}
          >
            {this.state.id === null ? (
              <Typography
                variant="h5"
                gutterBottom
                color="primary"
                style={{ fontWeight: '700', fontSize: '25px' }}
              >
                Adicionar Promoção
              </Typography>
            ) : (
              <EditShowId text="Editar Promoção" id={this.state.id} />
            )}

            {!this.state.id && (
              <Typography variant="h5" gutterBottom color="primary">
                Cadastro da oferta{' '}
              </Typography>
            )}
            <br />
            <form onSubmit={this.checkInformation}>
              <TextField
                placeholder=" Adicione o Título da Promoçã Ex.: Promoção Triibo..."
                className="input-field"
                type="text"
                value={this.state.title === null ? '' : this.state.title}
                id="title"
                label="Título da Promoção"
                onChange={this.handleChange('title')}
                required={this.state.isPublished}
              />

              <TextField
                placeholder=" Descreva um pouco a promoção Ex.: A cada compra na Triibo..."
                className="input-field"
                multiline
                type="text"
                value={this.state.description === null ? '' : this.state.description}
                id="description"
                required={this.state.isPublished}
                label="Descrição da Promoção"
                onChange={this.handleChange('description')}
              />

              <br />

              <div style={{ paddingTop: '1em' }}>
                <FormLabel name="2f">Tags da Promoção*</FormLabel>
                <div>
                  <S.TagsContainer>
                    <S.TagsAddContainer>
                      <D.TriiboTextField
                        rows={1}
                        placeholder={'Insira o tipo e aperte enter'}
                        value={this.state.tagCapture}
                        onChange={(e) => {
                          this.handleTagCapture(e);
                        }}
                        onKeyDown={(e) => {
                          this.handlePressEnter(e);
                        }}
                      />
                      <S.TagsAddButton
                        blue={true}
                        onClick={(e) => {
                          this.handlePushTag(e);
                        }}
                      >
                        Adicionar tag
                      </S.TagsAddButton>
                    </S.TagsAddContainer>
                    <D.TriiboTagsField>
                      <D.TriiboTagsPlaceholder
                        visibility={this.state.tags.length === 0 ? false : true}
                      >
                        Digite a tag desejada no campo acima e pressione o botão para adicioná-las
                      </D.TriiboTagsPlaceholder>
                      {this.state.tags.length > 0 &&
                        this.state.tags.map((body, index) => (
                          <>
                            <D.UserTags key={index}>
                              <D.TagText>{body}</D.TagText>
                              <D.TagDelete
                                onClick={(e) => {
                                  e.preventDefault();
                                  let newArr = this.state.tags;
                                  newArr.splice(index, 1);
                                  this.setState({
                                    tags: [...newArr],
                                  });
                                }}
                              >
                                {<BsXLg style={{ color: 'white' }} />}
                              </D.TagDelete>
                            </D.UserTags>
                          </>
                        ))}
                    </D.TriiboTagsField>
                  </S.TagsContainer>
                </div>
              </div>

              <br />
              <br />
              <br />

              <Typography
                style={{
                  marginTop: '10px',
                  marginBottom: '-15px',
                  fontWeight: '700',
                  fontSize: '20px',
                }}
                variant="h5"
                gutterBottom
                color="primary"
              >
                Fotos da promoção
              </Typography>

              <br />
              <br />
              <div className="flex-external">
                <div
                  className="img-thumb"
                  style={{
                    maxWidth: '400px',
                    position: 'relative',
                    top: '0',
                    left: '0',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    color="secondary"
                    style={{ fontWeight: '550', textAlign: 'center' }}
                  >
                    Thumbnail (aparecerá em "Promoções Próximas")
                  </Typography>

                  <img
                    src={thumbnailPromocao}
                    alt="Thumbnail"
                    style={{
                      width: '100%',
                      maxWidth: '400px',
                      maxHeight: '447px',
                      position: 'relative',
                      top: '0',
                      left: '0',
                    }}
                  />

                  {/* style={{ borderRadius: "5%", position: 'absolute', top: '154.5px', left: '12px', width: "118px" }} */}
                  <img
                    className="img-position-simples"
                    alt="thumbnail"
                    src={
                      this.state.thumbnailFile
                        ? this.state.thumbnailFile
                        : this.state.loadedImageThumbnail
                        ? this.state.loadedImageThumbnail
                        : placeholder
                    }
                  />

                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <input
                      value=""
                      style={{ display: 'none' }}
                      type="file"
                      accept="image/*"
                      onChange={this.fileChangedHandler('thumbnailFile', 'thumbnail')}
                      id="thumbnail"
                    />

                    <label htmlFor="thumbnail">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{
                          marginTop: '15px',
                          fontWeight: '550',
                          marginLeft: '20px',
                          color: '#fff',
                          textTransform: 'capitalize',
                        }}
                        startIcon={<AddPhotoAlternate />}
                      >
                        Adicionar Foto
                      </Button>

                      <Tooltip title="As imagens devem ser 4x3.">
                        <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                      </Tooltip>
                    </label>
                  </div>
                </div>

                {/* Lista de fotos */}
                <div>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    color="secondary"
                    className="style-text"
                    style={{ fontWeight: '550' }}
                  >
                    Lista de Fotos (aparecerá em "Detalhe do Estabelecimento")
                  </Typography>

                  <section className="grid-style">
                    <div
                      className="Foto-detalhe"
                      style={{
                        width: '100%',
                        maxWidth: '400px',
                        position: 'relative',
                        top: '0',
                        left: '0',
                      }}
                    >
                      <img
                        alt="Lista de Fotos"
                        src={listaFotos}
                        style={{
                          width: '100%',
                          maxWidth: '392px',
                          position: 'relative',
                          top: '0',
                          left: '0',
                        }}
                      />

                      <StyledBadge2
                        style={{
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          height: '100%',
                          width: '100%',
                        }}
                        invisible={
                          (this.state.loadedImagePhoto && this.state.loadedImagePhoto[0]) ||
                          (this.state.photoFile && this.state.photoFile[0])
                            ? false
                            : true
                        }
                        badgeContent={
                          <Tooltip
                            title={
                              (this.state.loadedImagePhoto && this.state.loadedImagePhoto[0]) ||
                              (this.state.photoFile && this.state.photoFile[0])
                                ? 'Para trocar clique em uma imagem ao lado'
                                : 'Utilize o botão "Adicionar fotos"'
                            }
                            style={{ height: '100%', width: '100%' }}
                          >
                            <Help color="secondary" style={{ fontSize: 18 }} />
                          </Tooltip>
                        }
                        color="primary"
                      >
                        <img
                          src={
                            this.state.photoFile
                              ? this.state.photoFile
                              : this.state.loadedImagePhoto
                              ? this.state.loadedImagePhoto
                              : placeholder
                          }
                          alt="lista de fotos"
                          width="100%"
                          style={{
                            maxWidth: '393px',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                          }}
                        />
                      </StyledBadge2>

                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {this.state.loadedImagePhoto || this.state.photoFile ? (
                          <div>
                            <input
                              value=""
                              style={{ display: 'none' }}
                              type="file"
                              accept="image/*"
                              onChange={this.photoListChangedHandler}
                              id="listaFotos"
                            />
                            <label htmlFor="listaFotos">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                style={{
                                  marginTop: '15px',
                                  fontWeight: '550',
                                  color: '#fff',
                                  textTransform: 'capitalize',
                                }}
                                startIcon={<AddPhotoAlternate />}
                              >
                                Adicionar Foto Na Lista
                              </Button>

                              <Tooltip title="As imagens devem ser 4x3.">
                                <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                              </Tooltip>
                            </label>
                          </div>
                        ) : (
                          <div>
                            <input
                              value=""
                              id="photoStore"
                              style={{ display: 'none' }}
                              type="file"
                              accept="image/*"
                              onChange={this.fileChangedHandler('photoFile', 'photo')}
                            />
                            <label htmlFor="photoStore">
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                style={{
                                  marginTop: '15px',
                                  fontWeight: '550',
                                  color: '#fff',
                                  textTransform: 'capitalize',
                                }}
                                startIcon={<AddPhotoAlternate />}
                              >
                                Adicionar Foto De Detalhe
                              </Button>
                            </label>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="lista-detalhes" id="photoList">
                      {fotos}
                    </div>
                  </section>
                </div>
              </div>

              <br />

              <TextField
                className="input-field"
                type="text"
                placeholder="Adicione um Título mais detalhado Ex.: Promoção Triibo não perca está oportunidade... "
                value={this.state.detailsTitle === null ? '' : this.state.detailsTitle}
                id="detailsTitle"
                label="Título da Promoção Detalhado"
                onChange={this.handleChange('detailsTitle')}
              />

              <TextField
                className="input-field"
                multiline
                type="text"
                placeholder="Descreva com mais detalhes Ex.: Usando o nome Triibo recebará um Desconto Especial..."
                value={this.state.detailsDescription === null ? '' : this.state.detailsDescription}
                id="detailsDescription"
                label="Descrição da Promoção Detalhado"
                onChange={this.handleChange('detailsDescription')}
                gutterBottom
              />

              <br />
              <br />

              <Downshift
                id="downshift-multiple"
                inputValue={keywords}
                onChange={this.handleChangeDownshift}
                selectedItem={selectedItem}
                style={{ width: '100%' }}
              >
                {({
                  getInputProps,
                  getItemProps,
                  isOpen,
                  inputValue: inputValue2,
                  selectedItem: selectedItem2,
                  highlightedIndex,
                }) => (
                  <div className={classes.container} style={{ width: '100%', marginTop: '1em' }}>
                    {renderInput({
                      fullWidth: true,
                      classes,
                      InputProps: getInputProps({
                        startAdornment: selectedItem.map((item) => (
                          <Chip
                            key={item}
                            tabIndex={-1}
                            label={item}
                            className={classes.chip}
                            onDelete={this.handleDeleteDownshift(item)}
                          />
                        )),
                        onChange: this.handleChange('keywords'),
                        onKeyDown: this.handleKeyDown,
                        placeholder: 'Selecione a keyword na lista abaixo',
                      }),
                      label: 'Keywords',
                    })}
                    {isOpen ? (
                      <Paper className={classes.paper} square>
                        {getSuggestions(inputValue2).map((suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({ item: suggestion.label }),
                            highlightedIndex,
                            selectedItem: selectedItem2,
                          })
                        )}
                      </Paper>
                    ) : null}
                  </div>
                )}
              </Downshift>
              <br />
              <Downshift
                id="downshift-multiple"
                inputValue={keywordsConsume}
                onChange={this.handleChangeDownshiftConsume}
                selectedItem={selectedItemConsume}
                style={{ width: '100%' }}
              >
                {({
                  getInputProps,
                  getItemProps,
                  isOpen,
                  inputValue: inputValue3,
                  selectedItem: selectedItem3,
                  highlightedIndex,
                }) => (
                  <div className={classes.container} style={{ width: '100%', marginTop: '1em' }}>
                    {renderInput({
                      fullWidth: true,
                      classes,
                      InputProps: getInputProps({
                        startAdornment: selectedItemConsume.map((item) => (
                          <Chip
                            key={item}
                            tabIndex={-1}
                            label={item}
                            className={classes.chip}
                            onDelete={this.handleDeleteDownshiftConsume(item)}
                          />
                        )),
                        onChange: this.handleChange('keywordsSetConsumption'),
                        onKeyDown: this.handleKeyDown,
                        placeholder: 'Selecione as keywords de consumo',
                      }),
                      label: 'Keywords de consumo',
                    })}
                    {isOpen ? (
                      <Paper className={classes.paper} square>
                        {getSuggestions(inputValue3).map((suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({ item: suggestion.label }),
                            highlightedIndex,
                            selectedItem: selectedItem3,
                          })
                        )}
                      </Paper>
                    ) : null}
                  </div>
                )}
              </Downshift>
              <TextField
                style={{ width: '100%' }}
                placeholder="Você não tem essa permissão !"
                className="input-field"
                disabled={this.state.keywordsSetConsumptionList <= 0}
                value={this.state.messageBlockedKw === null ? '' : this.state.messageBlockedKw}
                multiline
                onChange={this.handleChange('messageBlockedKw')}
                type="text"
                id="messageBlockedKw"
                required={this.state.keywordsSetConsumptionList}
                label="Mensagem de bloqueio por keyword"
              />

              <br />
              <br />
              <br />

              <FormLabel component="legend">Prioridade da Oferta</FormLabel>
              <Select
                style={{ minWidth: '500px' }}
                value={this.state.region}
                onChange={this.handleChange('region')}
                inputProps={{ id: 'region' }}
              >
                <MenuItem value="regional">Regional</MenuItem>
                <MenuItem value="national">Nacional</MenuItem>
                <MenuItem value="local">Local</MenuItem>
              </Select>

              <br />

              <TextField
                style={{ width: '300px' }}
                className="input-field"
                onChange={this.handleChange('level')}
                value={this.state.level}
                type="number"
                id="level"
                required={this.state.isPublished}
                label="Hierarquia da Oferta"
              />

              <TextField
                // style={{ width: "300px" }}

                className="input-field"
                onChange={this.handleChange('checkoutCode')}
                value={this.state.checkoutCode}
                type="text"
                id="checkoutCode"
                required={this.state.checkoutCode}
                InputLabelProps={{
                  shrink: this.state.checkoutCode ? true : false,
                }}
                label="Código de verificação"
              />

              <Input
                placeholder="Adicione o Link da Promoção"
                required={this.state.isPublished}
                id="url"
                fullWidth={true}
                value={this.state.url === null ? '' : this.state.url}
                style={{ marginTop: '30px' }}
                onChange={this.handleChange('url')}
                startAdornment={
                  <Tooltip title="Verificar a URL">
                    <IconButton
                      onClick={() => {
                        let url = this.state.url;

                        if (
                          url.indexOf('{code}') > 0 ||
                          url.indexOf('{email}') > 0 ||
                          url.indexOf('{name}') > 0 ||
                          url.indexOf('{uid}') > 0
                        ) {
                          window.open(url, '_blank');
                        } else {
                          this.setState({
                            openCheck: true,
                            messageAlert: 'URL inválida!',
                          });
                        }
                      }}
                    >
                      <Send />
                    </IconButton>
                  </Tooltip>
                }
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.droidWebView}
                    onChange={this.handleChangeSwitch('droidWebView')}
                    color="primary"
                  />
                }
                label={'Ver site dentro do aplicativo em Andoid?'}
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.iOSWebView}
                    onChange={this.handleChangeSwitch('iOSWebView')}
                    color="primary"
                  />
                }
                label={'Ver site dentro do aplicativo em Ios?'}
              />

              <br />

              <div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.campanhaTriibo}
                      onChange={this.handleChangeSwitch('campanhaTriibo')}
                      color="primary"
                    />
                  }
                  label={'Campanha Triibo?'}
                />

                <br />
                <br />

                <TextField
                  className="input-field"
                  value={this.state.buttonLabel === null ? '' : this.state.buttonLabel}
                  multiline
                  onChange={this.handleChange('buttonLabel')}
                  type="text"
                  id="buttonLabel"
                  label="Texto do botão"
                />

                <TextField
                  className="input-field"
                  value={this.state.buttonDescription === null ? '' : this.state.buttonDescription}
                  multiline
                  onChange={this.handleChange('buttonDescription')}
                  type="text"
                  id="buttonDescription"
                  label="Descrição do botão"
                />

                <TextField
                  className="input-field"
                  value={this.state.goToPlaceLabel === null ? '' : this.state.goToPlaceLabel}
                  multiline
                  onChange={this.handleChange('goToPlaceLabel')}
                  type="text"
                  id="goToPlaceLabel"
                  label="Texto do botão para o estabelecimento"
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.showButtonDescription}
                      onChange={this.handleChangeSwitch('showButtonDescription')}
                      color="primary"
                    />
                  }
                  label={'Mostrar descrição do botão?'}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.showGoToPlace}
                      onChange={this.handleChangeSwitch('showGoToPlace')}
                      color="primary"
                    />
                  }
                  label={'Mostrar botão de visualizar estabelecimento?'}
                />
              </div>

              <br />
              <br />

              <section>
                <Grid container spacing={4} alignItems="stretch" flexDirection="column">
                  <Grid item>
                    <TextField
                      id="enableDate"
                      label="Data de Ativação"
                      type="datetime-local"
                      value={this.state.enableDate !== null ? this.state.enableDate : ''}
                      InputLabelProps={{ shrink: true }}
                      onChange={this.handleChange('enableDate')}
                      required={this.state.isPublished}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      id="disableDate"
                      label="Data de Desativação"
                      type="datetime-local"
                      value={this.state.disableDate !== null ? this.state.disableDate : ''}
                      InputLabelProps={{ shrink: true }}
                      onChange={this.handleChange('disableDate')}
                      required={this.state.isPublished}
                    />
                  </Grid>
                </Grid>
              </section>

              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.isPublished}
                    onChange={this.handleChangeSwitch('isPublished')}
                    color="primary"
                  />
                }
                label={'Publicar a promoção?'}
              />

              <br />
              <br />

              {/* para promoções novas */}
              {this.state.wherePromo === null && (
                <>
                  <Button
                    style={{
                      fontWeight: '550',
                      marginTop: '20px',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => this.goBack()}
                    startIcon={<ArrowBack color="white" />}
                  >
                    Voltar
                  </Button>

                  <Button
                    style={{
                      fontWeight: '550',
                      marginLeft: '10px',
                      marginTop: '20px',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={<DoneAllIcon color="white" />}
                  >
                    {isAdminPage()
                      ? 'Salvar'
                      : !this.state.id
                      ? 'Solicitar Aprovação'
                      : this.state.aprovacao && this.state.aprovacao.status === 'negado'
                      ? 'Solicitar Revisão'
                      : 'Atualizar Informação'}
                  </Button>
                </>
              )}

              {/* para promoções existentes */}
              {this.state.wherePromo === 'estabelecimento-promo' && (
                <>
                  <Button
                    style={{
                      fontWeight: '550',
                      marginTop: '20px',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => this.goBack()}
                    startIcon={<ArrowBack color="white" />}
                  >
                    Voltar
                  </Button>

                  <Button
                    style={{
                      fontWeight: '550',
                      marginLeft: '10px',
                      marginTop: '20px',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={<DoneAllIcon color="white" />}
                  >
                    {isAdminPage()
                      ? 'Salvar'
                      : !this.state.id
                      ? 'Solicitar Aprovação'
                      : this.state.aprovacao && this.state.aprovacao.status === 'negado'
                      ? 'Solicitar Revisão'
                      : 'Atualizar Informação'}
                  </Button>
                </>
              )}

              {this.state.wherePromo === 'curadoria-promo' && (
                <S.CardContainer style={{ marginTop: 20 }}>
                  <S.SideContainer>
                    <S.SectionLabelContainer style={{ padding: '0rem 2rem 2rem 2rem' }}>
                      <Box sx={{ paddingBottom: '4rem' }}>
                        <S.TitleH2>Campo de Mensagem</S.TitleH2>
                      </Box>

                      <Box>
                        <form onSubmit={(e) => e.preventDefault()}>
                          <S.TagsLabelContainer style={{ marginTop: '0rem' }}>
                            Mensagem de Avaliação !
                          </S.TagsLabelContainer>

                          <br />
                          <D.TriiboTextField
                            disabled={this.state.status !== 'aberto'}
                            rows={1}
                            placeholder={'Mensagem...'}
                            maxLength="40"
                            onChange={(e) => this.setState({ comment: e.target.value })}
                            value={this.state.comment === '' ? '' : this.state.comment}
                          />
                          <div>
                            <ToastContainer autoClose={2000} />
                            <S.TagsAddButton
                              disabled={this.state.status !== 'aberto' ? true : false}
                              onClick={(e) => {
                                this.approvePromotion(e);
                              }}
                              style={{
                                marginTop: '20px',
                              }}
                            >
                              Aprovar
                            </S.TagsAddButton>

                            <S.FailButton
                              disabled={this.state.status !== 'aberto' ? true : false}
                              onClick={(e) => {
                                this.disapprovePromotion(e);
                              }}
                              style={{
                                marginLeft: '10px',
                                marginTop: '20px',
                              }}
                            >
                              Reprovar
                            </S.FailButton>
                          </div>
                        </form>
                      </Box>
                    </S.SectionLabelContainer>
                  </S.SideContainer>
                </S.CardContainer>
              )}
            </form>

            {/*ALERTS E DIALOGS*/}
            <Dialog
              open={this.state.open}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.handleCloseAlert}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                {'Deseja voltar a página anterior?'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  As alterações realizadas não serão salvas.
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  cancelar
                </Button>
                <Button onClick={() => window.history.back()} color="primary">
                  voltar
                </Button>
              </DialogActions>
            </Dialog>

            <Snackbar
              anchorOrigin={{
                vertical: this.state.vertical,
                horizontal: this.state.horizontal,
              }}
              open={this.state.openCheck}
              onClose={this.handleCloseAlert}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">{this.state.messageAlert}</span>}
            />
            {/*FIM ALERTS E DIALOGS*/}

            {/* MODAL PARA CROPAR IMAGEM */}
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.openModal}
            >
              <div style={getModalStyle()}>
                <Typography variant="h5" gutterBottom color="primary" style={{ fontWeight: '600' }}>
                  Editar Imagem
                </Typography>

                <div
                  style={{
                    maxWidth: '40%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  {this.state.imageToCrop === undefined ||
                  this.state.imageToCrop === null ? null : (
                    <Cropper
                      src={this.state.imageToCrop[this.state.indexCrop]}
                      ref={(ref) => {
                        this.image = ref;
                      }}
                      ratio={4 / 3}
                    />
                  )}
                </div>

                <div style={{ position: 'fixed', bottom: 10 }}>
                  <Button
                    style={{
                      fontWeight: '550',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => this.handleCloseModal()}
                    startIcon={<ArrowBack color="white" />}
                  >
                    Cancelar
                  </Button>

                  <Button
                    style={{
                      fontWeight: '550',
                      marginLeft: '10px',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (this.state.imageCroped === 'photoListFile') {
                        this.photoListCropImage('image');
                      } else {
                        this.cropImage('image');
                      }
                    }}
                    startIcon={<CropFreeIcon color="white" />}
                  >
                    Cortar Imagem
                  </Button>
                </div>
              </div>
            </Modal>
            {/* FIM MODAL PARA CROPAR IMAGEM */}
          </div>
        );
      }
    }
  };
}

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    id: state.storeBusinessPartnerModel.id,
    isPublished: state.storeBusinessPartnerModel.isPublished,
    detailsTitle: state.storeBusinessPartnerModel.detailsTitle,
    detailsDescription: state.storeBusinessPartnerModel.detailsDescription,
    title: state.storeBusinessPartnerModel.title,
    description: state.storeBusinessPartnerModel.description,
    establishmentId: state.storeBusinessPartnerModel.establishmentId,
    establishmentName: state.storeBusinessPartnerModel.establishmentName,
    lat: state.storeBusinessPartnerModel.lat,
    long: state.storeBusinessPartnerModel.long,
    dueDate: state.storeBusinessPartnerModel.dueDate,
    url: state.storeBusinessPartnerModel.url,
    photo: state.storeBusinessPartnerModel.photo,
    thumbnail: state.storeBusinessPartnerModel.thumbnail,
    type: state.storeBusinessPartnerModel.type,
    droidWebView: state.storeBusinessPartnerModel.droidWebView,
    iOSWebView: state.storeBusinessPartnerModel.iOSWebView,
    pathname: state.storeBusinessPartnerModel.pathname,
    checkoutCode: state.storeBusinessPartnerModel.checkoutCode,
    buttonLabel: state.storeBusinessPartnerModel.buttonLabel,
    buttonDescription: state.storeBusinessPartnerModel.buttonDescription,
    showButtonDescription: state.storeBusinessPartnerModel.showButtonDescription,
    goToPlaceLabel: state.storeBusinessPartnerModel.goToPlaceLabel,
    showGoToPlace: state.storeBusinessPartnerModel.showGoToPlace,
    campanhaTriibo: state.storeBusinessPartnerModel.campanhaTriibo,

    loading: state.storeBusinessPartnerComponent.loading,
    success: state.storeBusinessPartnerComponent.success,
    error: state.storeBusinessPartnerComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  updateStoreBusinessPartnerComponent: (
    id,
    isPublished,
    level,
    keywords,
    keywordsSetConsumptionList,
    messageBlockedKw,
    tags,
    detailsTitle,
    detailsDescription,
    title,
    description,
    establishmentId,
    establishmentName,
    lat,
    long,
    dueDate,
    enableDate,
    disableDate,
    url,
    photo,
    thumbnail,
    type,
    region,
    droidWebView,
    iOSWebView,
    newImgThumbnail,
    oldImageNameThumbnail,
    newImgPhoto,
    oldImageNamePhoto,
    buttonLabel,
    buttonDescription,
    showButtonDescription,
    goToPlaceLabel,
    showGoToPlace,
    oldPhotoList,
    photoListFile,
    campanhaTriibo,
    checkoutCode
  ) =>
    updateStoreBusinessPartnerAction(
      dispatch,
      id,
      isPublished,
      level,
      keywords,
      keywordsSetConsumptionList,
      messageBlockedKw,
      tags,
      detailsTitle,
      detailsDescription,
      title,
      description,
      establishmentId,
      establishmentName,
      lat,
      long,
      dueDate,
      enableDate,
      disableDate,
      url,
      photo,
      thumbnail,
      type,
      region,
      droidWebView,
      iOSWebView,
      newImgThumbnail,
      oldImageNameThumbnail,
      newImgPhoto,
      oldImageNamePhoto,
      buttonLabel,
      buttonDescription,
      showButtonDescription,
      goToPlaceLabel,
      showGoToPlace,
      oldPhotoList,
      photoListFile,
      campanhaTriibo,
      checkoutCode
    ),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(StoreBusinessPartnerExternal);
